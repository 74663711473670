import emailjs from "@emailjs/browser";
import { useState } from "react";
import { countries } from "countries-list";

const countryCodes = Object.keys(countries);
const countryInfo = countryCodes
  .map((code) => {
    return {
      name: countries[code].name,
      phone: "+" + countries[code].phone[0],
    };
  })
  .sort();

export const ContactForm = ({ height }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setJobTitle("");
    setCompanyName("");
    setCountry("");
    setPhoneNumber("");
    setSubject("");
    setMessage("");
    setPrivacy(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let problem = false;
    let problemMessage = "";

    const serviceId = "service_hl8jaeq";
    const templateId = "template_pgoctl5";
    const publicKey = "gKf_CDJFpRtI8fkj3";

    const templateParams = {
      from_name: firstName + " " + lastName,
      from_email: email,
      phone_number: phoneNumber,
      jobTitle: jobTitle, //Later have this be an option menu
      companyName: companyName,
      country: country,
      email_subject: subject,
      to_name: "Jawad",
      message: message,
    };

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      subject === "" ||
      message === "" ||
      !privacy
    ) {
      problem = true;
      problemMessage = `
        Sorry, we couldn't send email because you didn't type your:

            ${firstName === "" ? "First Name" : ""}
            ${lastName === "" ? "Last Name" : ""}
            ${email === "" ? "Email" : ""}
            ${subject === "" ? "Subject" : ""}
            ${message === "" ? "Message" : ""}
            `;
    }

    if (!problem) {
      emailjs.send(serviceId, templateId, templateParams, publicKey).then(
        () => {
          console.log("Email delivered successfully");
          alert(
            `Congratulations ${firstName}! Your message has been sent to us successfully.`
          );
          reset();
        },
        (error) => {
          console.log("Error sending email", error.text);
        }
      );
    } else {
      alert(problemMessage);
    }
  };

  return (
    <div
      className="contact-form-section"
      style={{
        width: "50%",
        height: height + "px",
        backgroundImage: "linear-gradient(0deg, black, black)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: "white",
          width: "90%",
          height: "20%",
          fontSize: "600%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Contact Form
      </div>
      <div
        className="form-wrapper"
        style={{
          width: "90%",
          height: "80%",
          fontSize: "200%",
        }}
      >
        <form
          onSubmit={handleSubmit}
          className="email-form"
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "5% 5%",
          }}
        >
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>First Name</label>
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Country</label>
            <select onChange={(e) => setCountry(e.target.value)}>
              {countryInfo.map((country, index) => {
                return (
                  <option
                    selected={country.name === "Canada" && "selected"}
                    key={country.name + index}
                    className="country-option"
                  >
                    {country.name}
                  </option> //Find a way to do area code later on
                );
              })}
            </select>
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Phone</label>
            <input
              type="tel"
              placeholder="123-456-7890"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            />
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Job Title</label>
            <input
              type="text"
              placeholder="Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Company Name</label>
            <input
              type="text"
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </section>
          <section style={{ display: "flex", flexDirection: "column" }}>
            <label>Subject</label>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gridColumn: "1 / span 2",
            }}
          >
            <label>Message</label>
            <textarea
              cols={"30"}
              rows={"5"}
              type="text"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              gridColumn: "1 / span 2",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                color: "white",
                fontSize: "1rem",
              }}
            >
              <input
                type="checkbox"
                value={privacy}
                onChange={(e) => setPrivacy(e.target.value)}
              />
              <span>I have read the terms and agreements...</span>
            </div>
          </section>
          {/* <button className="reset" onClick={() => console.log("H")}>
            Reset
          </button> */}
          <button className="send" type="submit">
            Send
          </button>
        </form>
      </div>
    </div> //Will be in glass box beside article which are placed over imageSection
  );
};
