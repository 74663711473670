import {
  contentStyles,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
} from "../../../components/sections/content/styles";

const section1Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "title",
      text: `Are You Concerned About the Increasing Complexity of Cyber Threats ?`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "100%",
    contentData: {
      type: "subtitle",
      text: `We provide a full suite of cybersecurity solutions designed to address the complexities of modern
threats. Our expert team utilizes cutting-edge technologies and proactive strategies to ensure your
systems are protected against both known and emerging threats.`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["Canadian IT company", "cyber threats"],
      continuous: false,
    },
  },
];

const section2Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "title",
      text: `Are You Struggling with Ensuring Security Across Diverse Environments?`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "subtitle",
      text: `Managing security across multiple platforms—such as cloud environments, operational technology,
and traditional IT infrastructure—can be overwhelming. How can you effectively protect all these
different areas without compromising on quality or performance?`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["wide variety"],
      continuous: false,
    },
  },
];

const section3Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "title",
      text: `Tailored Solutions for Every Environment:`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "subtitle",
      text: `Our services are designed to provide seamless security across all environments, ensuring
comprehensive protection whether you’re operating on-premises, in the cloud, or within industrial
settings. We address the unique challenges of each environment with specialized strategies and tools.
      `,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [
        "well versed",
        "latest technologies",
        "AI",
        "IT professionals",
        "guarantee the protection",
      ],
      continuous: false,
    },
  },
];

const section4Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "15%",
    contentData: {
      type: "title",
      text: `Our Valued Partnerships`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "bottom",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "35%",
    contentData: {
      type: "subtitle",
      text: `At The Solutioners, we believe that collaboration is key to achieving exceptional results. We are
  proud to work with a diverse array of esteemed business partners who share our commitment to
  excellence in cybersecurity. Each partnership enhances our ability to deliver top-tier solutions and
  services tailored to meet the unique needs of our clients.`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["wide variety"],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "15%",
    contentData: {
      type: "title",
      text: `By choosing our services, you benefit from`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "35%",
    contentData: {
      type: "subtitle",
      text: `Up-to-Date Expertise: We stay ahead of evolving threats and new technologies to offer the
most relevant and effective solutions.

 Customized Solutions: Our services are tailored to meet the specific needs of your
organization, ensuring optimal protection and performance.

 Innovative Technology: We utilize advanced tools and AI to enhance our threat detection and
response capabilities, providing superior security.

Protect your organization with our cutting-edge cybersecurity solutions and experience the peace of
mind that comes from knowing your digital assets are secure. Contact us today to learn more about
how we can safeguard your business against the evolving landscape of cyber threats..`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["wide variety"],
      continuous: false,
    },
  },
];

const section5Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "100%",
    contentData: {
      type: "title",
      text: `We offer a range of specialized services to meet your cybersecurity needs:`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
];

export const servicesContent = [
  {
    height: "500px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section1Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "500px",
    style: superSectionStyles.blackDiagonalBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section2Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "700px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section3Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "900px",
    style: superSectionStyles.blackDiagonalBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section4Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "200px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section5Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
];
