import { Scroller } from "../content/Scroller";

export const VideoCaption = ({ caption, visibility, duration }) => {
  return (
    <div
      className="summary container"
      style={{
        height: "33%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className={`summary wrapper`}
        style={{
          height: "100%",
          width: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={`summary text-wrapper ${visibility}`}
          style={{
            height: "50%",
            width: "100%",
            display: "table",
            fontSize: "200%",
            borderTop: "1px solid white",
          }}
        >
          <p
            className={`summary text-box-wrapper ${visibility}`}
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            {caption}
          </p>
        </div>
        {/* <Scroller height={300} width="50%" content={caption} duration={duration} /> */}
      </div>
    </div>
  );
};
