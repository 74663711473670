import React from "react";
import {
  contentStyles,
  purpleBlueGradient,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
} from "./styles";
import { Container } from "./Container";

const formatQuestion = (type, topic) => {
  let question = `What is ${topic} ?`;
  let stylizedWord = `${topic}`;

  if (type === 2) {
    question = `What is the importance of ${topic} ?`;
    stylizedWord = "importance";
  } else if (type === 3) {
    question = `What are some key parts of ${topic} ?`;
    stylizedWord = "key parts";
  } else if (type === 4) {
    question = `What are ${topic} ?`;
  } else if (type === 5) {
    question = `Why are ${topic} important?`;
    stylizedWord = "important";
  } else if (type === 6) {
    question = `Why are ${topic} responsible for?`;
    stylizedWord = "responsible";
  } else if (type === 7) {
    question = `What is a ${topic} solution?`;
  } else if (type === 8) {
    question = `Why is a ${topic} important?`;
    stylizedWord = "important";
  } else if (type === 9) {
    question = `What are some key features of a ${topic}?`;
    stylizedWord = "key features";
  }

  return {
    height: "500px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "100%",
                contentData: {
                  type: "title",
                  text: question,
                  textColor: "black",
                  xAlignment: "center",
                  yAlignment: "middle",
                  stylizedText: [stylizedWord],
                  continuous: false,
                },
              },
            ],
          },
        ],
        divideLine: "",
      },
    ],
  };
};

const formatDefinition = (definition, stylizedText) => {
  return {
    height: "500px",
    style: superSectionStyles.blackDiagonalBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "100%",
                contentData: {
                  type: "subtitle",
                  text: `${definition}`,
                  textColor: "white",
                  xAlignment: "center",
                  yAlignment: "middle",
                  stylizedText: [],
                  continuous: false,
                },
              },
            ],
          },
        ],
        divideLine: "",
      },
    ],
  };
};

const formatPitch = (pitchTitle, pitch, stylizedText) => {
  return {
    height: "750px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "33%",
                contentData: {
                  type: "title",
                  text: pitchTitle ? pitchTitle : "Why choose us ?",
                  textColor: "black",
                  xAlignment: "center",
                  yAlignment: "bottom",
                  stylizedText: [`${pitchTitle === "" && "us"}`],
                  continuous: false,
                },
              },
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "66%",
                contentData: {
                  type: "subtitle",
                  text: `${pitch}`,
                  textColor: "black",
                  xAlignment: "center",
                  yAlignment: "middle",
                  stylizedText: [],
                  continuous: false,
                },
              },
            ],
          },
        ],
        divideLine: "",
      },
    ],
  };
};

const formatList = (data, formatType, titleType) => {
  let formattedList = {};

  if (formatType === "grid") {
    formattedList = {
      height: "1500px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "content",
                  style: contentStyles.transparentBackgrounds.transparent,
                  height: "90%",
                  contentData: {
                    type: "grid",
                    numberOfColumns: 4,
                    contentData: data,
                    style: contentStyles.flatBackgrounds.darkCard,
                  },
                },
              ],
            },
          ],
          divideLine: "",
        },
      ],
    };
  } else if (formatType === "list") {
    formattedList = {
      height: "1000px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "content",
                  style: contentStyles.transparentBackgrounds.transparent,
                  height: "90%",
                  contentData: {
                    type: "grid",
                    numberOfColumns: 1,
                    contentData: data,
                    style: contentStyles.flatBackgrounds.darkCard,
                  },
                },
              ],
            },
          ],
          divideLine: "",
        },
      ],
    };
  } else if (formatType === "tabMenu") {
    formattedList = {
      height: "1500px",
      style: superSectionStyles.whiteFlatBackground,
      contentData: [
        {
          type: "section",
          height: "100%",
          style: sectionStyles.transparentBackgrounds.transparent,
          contentData: [
            {
              type: "subsection",
              height: "100%",
              style: subsectionStyles.transparentBackgrounds.transparent,
              contentData: [
                {
                  type: "content",
                  style: contentStyles.transparentBackgrounds.transparent,
                  height: "90%",
                  contentData: {
                    type: "tabMenu",
                    height: "90%",
                    width: "70%",
                    contentData: data,
                  },
                },
              ],
            },
          ],
          divideLine: "",
        },
      ],
    };
  }
  return formattedList;
};

const formatCard = (data, type) => {
  return data.map((item, i) => {
    if (type === "listItem") {
      return {
        type: "section",
        content: [
          {
            type: "subsection",
            style: subsectionStyles.transparentBackgrounds.transparentCard,
            height: "80%",
            contentData: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "100%",
                contentData: {
                  type: "title",
                  text: i,
                  textColor: "white",
                  xAlignment: "center",
                  yAlignment: "middle",
                  stylizedText: [],
                  continuous: false,
                },
              },
            ],
            divideLine: "1px solid white",
          },
          {
            type: "subsection",
            style: subsectionStyles.transparentBackgrounds.transparentCard,
            height: "100%",
            contentData: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "45%",
                contentData: {
                  type: "subtitle",
                  text: item.title,
                  textColor: "white",
                  xAlignment: "left",
                  yAlignment: "bottom",
                  stylizedText: [],
                  continuous: false,
                },
              },
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "55%",
                contentData: {
                  type: "paragraph",
                  text: item.text,
                  textColor: "white",
                  xAlignment: "left",
                  yAlignment: "top",
                  stylizedText: [],
                  continuous: false,
                },
              },
            ],
          },
        ],
      };
    } else if (type === "card") {
      return {
        type: "content",
        content: [
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: "30%",
            contentData: {
              type: "title",
              text: i,
              textColor: "white",
              xAlignment: "center",
              yAlignment: "bottom",
              stylizedText: [],
              continuous: false,
            },
          }, //This will be replaced with the icons later on
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: "25%",
            contentData: {
              type: "subtitle",
              text: item.title,
              textColor: "white",
              xAlignment: "center",
              yAlignment: "middle",
              stylizedText: [],
              continuous: false,
            },
          },
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: "40%",
            contentData: {
              type: "paragraph",
              text: item.text,
              textColor: "white",
              xAlignment: "center",
              yAlignment: "top",
              stylizedText: [],
              continuous: false,
            },
          },
        ],
      };
    } else if (type === "page") {
      return {
        title: item.title,
        content: [
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: "30%",
            contentData: {
              type: "title",
              text: i, //This will be the icon
              textColor: "black",
              xAlignment: "center",
              yAlignment: "middle",
              stylizedText: [],
              continuous: false,
            },
          },
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: "20%",
            contentData: {
              type: "subtitle",
              text: item.subtitle, //This will be the subtitle
              textColor: "black",
              xAlignment: "center",
              yAlignment: "middle",
              stylizedText: [],
              continuous: false,
            },
          },
          {
            type: "content",
            style: contentStyles.transparentBackgrounds.transparent,
            height: "50%",
            contentData: {
              type: "paragraph",
              text: item.text,
              textColor: "black",
              xAlignment: "center",
              yAlignment: "top",
              stylizedText: [],
              continuous: false,
            },
          },
        ],
      };
    }
  });
};

const formatSections = (sectionData) => {
  const {
    topic,
    definition,
    importance,
    keyPoints,
    stats,
    subtopics,
    pitchTitle,
    pitch,
    conclusion,
  } = sectionData;
  const cardData = formatCard(keyPoints, "card");
  const listData = formatCard(stats, "listItem");
  let subTopicBreakDown = null;
  if (subtopics !== null) {
    const subtopicData = formatCard(subtopics, "page");
    subTopicBreakDown = formatList(subtopicData, "tabMenu");
  }

  let questions = [1, 2, 3];
  if (topic === "system integrators") {
    questions = [4, 5, 6];
  } else if (topic === "BDR") {
    questions = [7, 8, 9];
  }

  return [
    formatQuestion(questions[0], topic),
    formatDefinition(definition),
    formatQuestion(questions[1], topic),
    formatDefinition(importance),
    formatQuestion(questions[2], topic),
    formatList(cardData, "grid"),
    subTopicBreakDown,
    formatList(listData, "list"),
    formatPitch("", conclusion),
    formatPitch(pitchTitle, pitch),
  ];
  //return formatted sections as object to give to generateContainers
};

export const ServiceSection = ({ sectionData }) => {
  const generateContainers = (sectionInfo) => {
    const containers = sectionInfo.map((section, index) => {
      if (section !== null) {
        return (
          <Container
            type={"superSection"}
            height={section.height}
            width={"100%"}
            style={section.style}
            index={index}
            contentData={section.contentData}
          />
        );
      } else {
        return;
      }
    });
    return containers;
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {generateContainers(formatSections(sectionData))}
    </div>
  );
};

//Takes data, and organizes it into format
/*
Format: (This is the general order, I can change the number)
0) Header and then Video

1) Question 1: What is (Service) ?
2) Answer: Definition (For design add gradient border to one side of of the text), then have an icon to the side which shows the service, use the gradient icons I have
3) Question 2: What is the importance of [service] ? 
4) Answer: Reason (On the other side is a 3d icon that is seen floating up and down)
5) Fact and Stat cards (windows, grid or row of cards, accordion, or article/list with cards going down, usage of animated and highlighted words and numbers as well)
6) Points display/Break down, can be done through either these formats window, grid, accordion, article with cards going down, each with their own icons as well) (Optional)
7) Sub-services: Break down of main subservices/features. (Optional)
8) Why choose us? Why choose us (Image or video background)
9) In short: Conclusion

10) Footer

6) and 7) Can be combined or separated
5) Can also include case study cards or question and answer cards as well

*/
