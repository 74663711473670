export const sectionData = {
  topic: "Cybersecurity",

  definition: `Cybersecurity is the practice of protecting systems, networks, and data from digital attacks, damage, or
unauthorized access. It involves a range of measures and technologies designed to safeguard
computers, servers, mobile devices, and the data they handle. The goal is to ensure the confidentiality,
integrity, and availability of information.`,

  importance: `
  Cybersecurity is crucial because it protects sensitive information and systems from malicious attacks,
   data breaches, and unauthorized access, which can lead to financial losses, reputational damage, and
   legal consequences. As our reliance on digital technologies and online platforms grows, so does the
   risk of cyber threats. Effective cybersecurity measures ensure the confidentiality, integrity, and
   availability of data, safeguarding personal, corporate, and governmental information from exploitation
    and disruption. Without robust cybersecurity, individuals and organizations would be vulnerable to a
    wide range of cyber-criminal activities that could compromise their safety and operational stability.`,

  keyPoints: [
    {
      title: `Network Security`,
      text: `Protects the integrity and usability of network and data by preventing
unauthorized access, misuse, or modification. It involves tools like firewalls, intrusion detection
systems, and secure network architecture.`,
    },
    {
      title: `Information Security`,
      text: `Focuses on protecting the data itself, ensuring that it remains confidential
and is not altered or destroyed without authorization. This often involves encryption and data
loss prevention technologies.`,
    },
    {
      title: `Application Security`,
      text: `Ensures that software and applications are designed and maintained
securely to prevent vulnerabilities that could be exploited by attackers. This includes secure
coding practices and regular security testing.`,
    },
    {
      title: `Operational Security`,
      text: `Involves the procedures and policies for handling and protecting data,
including how it is stored and shared. It also includes user access controls and other
administrative safeguards.`,
    },
    {
      title: `Endpoint Security`,
      text: `Protects individual devices such as computers and smartphones from being
exploited by malicious campaigns or malware. This typically involves antivirus software, anti-
malware tools, and regular updates.`,
    },
    {
      title: `IAM`,
      text: `Identity and Access Management (IAM), Ensures that only authorized individuals have access to
specific resources and data. This often involves authentication methods, such as passwords,
biometrics and authorization protocols.`,
    },
    {
      title: `Incident Response`,
      text: `Deals with how to handle and respond to security breaches or attacks. This
includes detecting, managing, and mitigating the impact of security incidents.`, //Might remove this
    },
    {
      title: `BCDR`,
      text: `Focuses on ensuring that a business can continue
operating and recover quickly in the event of a major security incident or other disruptions.`, //Might remove this
    },
  ],

  stats: [
    {
      title: `Data Breach Costs`,
      text: `According to IBM’s Cost of a Data Breach Report 2023, the average total
cost of a data breach is $4.45 million. This represents a significant increase from previous years,
reflecting the growing complexity and impact of breaches.`,
    },
    {
      title: `Growing Costs of Ransomware`,
      text: `The Cybersecurity Ventures Ransomware Report estimates that the
global cost of ransomware attacks will reach $30 billion by 2025, reflecting the growing financial
burden on businesses and individuals.`,
    },
    {
      title: `Cybersecurity Skills Gap`,
      text: `The (ISC)² Cybersecurity Workforce Study 2023 found a global shortage
of approximately 3.4 million cybersecurity professionals, underscoring the critical need for
skilled personnel in the field.`,
    },
  ],

  subtopics: [
    {
      title: `MSSP`,
      subtitle: `Stay Ahead of Threats with Our MSSP Solutions`,
      text: `
In an increasingly complex threat landscape, managing and maintaining your own security infrastructure
can be daunting. Our Managed Security Service Provider (MSSP) solutions offer a comprehensive
approach to protecting your business. We handle everything from threat detection to incident response,
ensuring that your systems are continuously monitored and protected. Our team of cybersecurity
experts operates around the clock, providing you with advanced security management, proactive threat
analysis, and seamless integration with your existing systems. Let us take the burden off your shoulders
so you can focus on growing your business, knowing your security is in expert hands.`,
    },
    {
      title: `Open Source SOC`,
      subtitle: `Empower Your Security with Our Open Source SOC`,
      text: `
Unlock the power of transparency and flexibility with our Open Source Security Operations Center (SOC)
services. We leverage cutting-edge, open-source tools to build a tailored SOC that fits your unique
needs. This approach not only reduces costs but also provides you with a customizable and scalable
security solution. Our Open Source SOC offers real-time threat monitoring, analysis, and response, all
while maintaining transparency and control over your security operations. Experience a powerful,
adaptable SOC that evolves with your needs and delivers exceptional protection without compromising
on quality.`,
    },
    {
      title: `SOC 365`,
      subtitle: `Comprehensive Protection with SOC 365`,
      text: `
Ensure round-the-clock security with our SOC 365 services. Our fully managed Security Operations
Center operates 24/7 to provide you with continuous monitoring, threat detection, and incident
response. With SOC 365, you gain access to state-of-the-art technology and a team of skilled analysts
who are always on alert to identify and neutralize threats before they impact your business. Whether
you&#39;re a small business or a large enterprise, SOC 365 offers a robust and reliable security solution
designed to protect your organization every hour of every day.`,
    },
    {
      title: `Dark Web Monitoring Services`,
      subtitle: `Protect Your Business from Hidden Threats with Dark Web Monitoring`,
      text: `
The dark web is a hidden realm where stolen data and credentials are traded and sold. Our Dark Web
Monitoring Services provide you with early warning of potential breaches by scanning these clandestine
areas for compromised information related to your business. We continuously monitor and analyze dark
web activities to identify any threats that could jeopardize your security. With proactive alerts and
actionable insights, you can address vulnerabilities before they become serious issues, keeping your
business one step ahead of cyber-criminals.`,
    },
    {
      title: `Email Security Services`,
      subtitle: `Secure Your Communications with Comprehensive Email Security`,
      text: `
Email is a primary channel for communication, but it’s also a common target for cyber threats. Our Email
Security Services offer a multi-layered defense to protect your inbox from malicious attacks.

Phishing Protection: Combat deceptive phishing attempts with our advanced phishing protection
solutions. We employ sophisticated algorithms and real-time scanning to identify and block fraudulent
emails, reducing the risk of falling victim to scams.

Email Encryption: Safeguard sensitive information with our email encryption services. We ensure that
your communications are encrypted both in transit and at rest, preventing unauthorized access and
maintaining confidentiality.

Spam Filtering: Keep your inbox clutter-free and secure with our robust spam filtering. Our technology
effectively filters out unwanted and potentially harmful emails, enhancing your productivity and
reducing the risk of exposure to malware.

Protect your digital communication channels with our comprehensive email security solutions, designed
to keep your business safe from evolving email threats.`,
    },
  ],

  pitchTitle: "Protect Your Digital World with Expert Cybersecurity Solutions",

  pitch: `In today’s interconnected world, safeguarding your digital assets is more critical than ever. At The Solutioners,
   we specialize in providing comprehensive cybersecurity services tailored to meet the
unique needs of your business. Our mission is to protect your organization from evolving cyber threats
and ensure your data remains secure and your operations uninterrupted.`,

  conclusion: `In a digital landscape fraught with evolving threats and increasing risks, robust cybersecurity is not just a
necessity, but a strategic imperative. At The Solutioners, we are dedicated to delivering
advanced, tailored cybersecurity solutions that safeguard your business against a myriad of cyber
threats. With our expert team, cutting-edge technology, and proactive approach, we ensure your data
remains secure, your compliance requirements are met, and your operations run smoothly. Invest in
your digital security today and protect your future with confidence. Contact us now to discover how our
comprehensive services can provide the ultimate defense for your organization.`,
  
  videoCaptions: `Cybersecurity encompasses a wide range of practices, technologies, and processes designed to safeguard networks, data, and hardware from malicious programs. At The Solutioners we provide a wide range of general cybersecurity services to help protect your system from being compromised as well as your personal data from being breached. These include email security services such as spam filtering and phishing protection. MSSP services such as SOC, and End Point Detection and Response. Along with dark web monitoring services for retrieval of breached information.`
};
