import { Container } from "./Container";
import { Layer } from "./Layer";

const containerHeight = 600;
const containerWidth = 1920;
const scrollbarWidth = 17;
const numberOfBoxes = 70;
const boxWidth = 100;
const boxHeight = 100;

const testRowContent = (
  <>
    <section
      style={{
        height: `100%`,
        width: `${500}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'dimgrey',
      }}
    >
      A
    </section>
    <section
      style={{
        height: `100%`,
        width: `${500}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(64,60, 62)',
      }}
    >
      B
    </section>
    <section
      style={{
        height: `100%`,
        width: `${500}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(70,70, 70)',
      }}
    >
      C
    </section>
  </>
);

const testColContent = (
  <>
    <section
      style={{
        width: `100%`,
        height: `${150}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "dimgrey",
      }}
    >
      A
    </section>
    <section
      style={{
        width: `100%`,
        height: `${150}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(64,60, 62)",
      }}
    >
      B
    </section>
    <section
      style={{
        width: `100%`,
        height: `${150}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(70,70, 70)",
      }}
    >
      C
    </section>
  </>
);

const testWallContent = (
  <>
    <section
      style={{
        height: `100%`,
        width: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'dimgrey',
      }}
    >
      A
    </section>
    <section
      style={{
        height: `100%`,
        width: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(64,60, 62)',
      }}
    >
      B
    </section>
    <section
      style={{
        height: `100%`,
        width: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(70,70, 70)',
      }}
    >
      C
    </section>
  </>
);

const testStackContent = (
  <>
    <section
      style={{
        width: `100%`,
        height: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'dimgrey',
      }}
    >
      A
    </section>
    <section
      style={{
        width: `100%`,
        height: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(64,60, 62)',
      }}
    >
      B
    </section>
    <section
      style={{
        width: `100%`,
        height: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(70,70, 70)',
      }}
    >
      C
    </section>
  </>
);

export const TestMain = () => {
  const createBoxes = (number, width, height, color) => {
    let boxes = [];
    for (let i = 1; i <= number; i++) {
      const box = (
        <section
          style={{
            width: `${width}px`,
            height: height !== null ? `${height}px` : `inherit`,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            backgroundColor: color,
            fontSize: "50px",
            // border: "1px solid black",
            // boxSizing: "border-box",
            // gridRowStart: '1',
            // gridRowEnd: '5',
            // gridColumnStart: '1',
            // gridColumnEnd: '10'
          }}
        >
          {i}
        </section>
      );
      boxes = [...boxes, box];
    }
    return boxes;
  };

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
        backgroundColor: "rgba(25,25,25)",
        fontSize: "100px",
      }}
    >
      {/* <section
        className="bi-wall"
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <section
            style={{
              height: "inherit",
              width: "inherit",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            A
          </section>
          <section
            style={{
              height: "inherit",
              width: "inherit",
              backgroundColor: "blue",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            B
          </section>
        </div>
      </section>
      <section
        className="tri-wall-with-stack"
        style={{
          height: `${containerHeight}px`,
          width: `${containerWidth}px`,
          backgroundColor: "orange",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${(containerWidth - 100) / 3}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <section
            style={{
              height: "inherit",
              width: "inherit",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            C
          </section>
        </div>
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${(containerWidth - 100) / 3}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <section
            style={{
              height: "inherit",
              width: "inherit",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <section
              style={{
                height: "33.33%",
                width: "inherit",
                backgroundColor: "aqua",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              D
            </section>
            <section
              style={{
                height: "33.33%",
                width: "inherit",
                backgroundColor: "lightgrey",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              E
            </section>
            <section
              style={{
                height: "33.33%",
                width: "inherit",
                backgroundColor: "lightblue",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              F
            </section>
          </section>
        </div>
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${(containerWidth - 100) / 3}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <section
            style={{
              height: "inherit",
              width: "inherit",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            G
          </section>
        </div>
      </section>
      <section
        className="box-wrapper"
        style={{
          height: `${containerHeight}px`,
          width: `${containerWidth}px`,
          backgroundColor: "lavender",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            backgroundColor: "darkgrey",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-evenly",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {createBoxes(70, boxWidth, boxHeight, "lightgreen")}
        </div>
      </section> */}
      {/* <section
        className="box--container row-scroll"
        style={{
          height: `${containerHeight}px`,
          width: `${containerWidth}px`,
          backgroundColor: "maroon",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            overflow: "auto",
          }}
        >
          <div
            style={{
              height: `${containerHeight - 100 - scrollbarWidth}px`,
              width: `${boxWidth * numberOfBoxes - scrollbarWidth}px`,
              backgroundColor: "midnightblue",
              display: "flex",
            }}
          >
            {createBoxes(numberOfBoxes, boxWidth, null, "magenta")}
          </div>
        </div>
      </section> */}
      {/* <section
        className="grid-container"
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "slategrey",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            backgroundColor: "navy",
            display: "grid",
            gridTemplateColumns: "repeat(20, 1fr)",
            // gridTemplateColumns: '100px 100px 100px 100px 100px 100px 100px 100px 100px ',
            // justifyContent: 'space-between',
            gap: "10px 10px",
            overflow: "auto",
          }}
        >
          {createBoxes(500, boxWidth, boxWidth, "purple")}
        </div>
      </section> */}
      {/* <section
        className="stack-wrapper"
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "coral",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <section
            style={{
              height: "33.33%",
              width: "inherit",
              backgroundColor: "aquamarine",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            A
          </section>
          <section
            style={{
              height: "33.33%",
              width: "inherit",
              backgroundColor: "crimson",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            B
          </section>
          <section
            style={{
              height: "33.33%",
              width: "inherit",
              backgroundColor: "chartreuse",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            C
          </section>
        </div>
      </section>  */}
      {/* <section
        className="stack-container-scroll"
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "darkslategrey",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            overflow: "auto",
          }}
        >
          <div
            style={{
              height: `${containerHeight - 100 - scrollbarWidth}px`,
              width: `${containerWidth - 100 - scrollbarWidth}px`,
            }}
          >
            <section
              style={{
                height: "400px",
                width: "inherit",
                backgroundColor: "salmon",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              A
            </section>
            <section
              style={{
                height: "300px",
                width: "inherit",
                backgroundColor: "cornsilk",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              B
            </section>
            <section
              style={{
                height: "400px",
                width: "inherit",
                backgroundColor: "darkcyan",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              C
            </section>
          </div>
        </div>
      </section>
      {/* <section
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "ivory",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          <section
            style={{
              height: `${100}px`,
              width: `100%`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "blue",
            }}
          >
            A
          </section>
          <section
            style={{
              height: `${100}px`,
              width: `100%`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "blue",
            }}
          >
            B
          </section>
          <section
            style={{
              height: `${100}px`,
              width: `100%`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "blue",
            }}
          >
            C
          </section>
        </div>
      </section>
      <section
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "ivory",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            overflow: "auto",
          }}
        >
          <div
            className="container"
            style={{
              height: `${1000}px`,
              width: `100%`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              backgroundColor: "red",
            }}
          >
            <section
              style={{
                height: `${100}px`,
                width: `100%`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "blue",
              }}
            >
              A
            </section>
            <section
              style={{
                height: `${100}px`,
                width: `100%`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "blue",
              }}
            >
              B
            </section>
            <section
              style={{
                height: `${100}px`,
                width: `100%`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "blue",
              }}
            >
              C
            </section>
          </div>
        </div>
      </section>
      <section
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "ivory",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="container"
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          <section
            style={{
              height: `100%`,
              width: `${100}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "blue",
            }}
          >
            A
          </section>
          <section
            style={{
              height: `100%`,
              width: `${100}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "blue",
            }}
          >
            B
          </section>
          <section
            style={{
              height: `100%`,
              width: `${100}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "blue",
            }}
          >
            C
          </section>
        </div>
      </section>
      <section
        style={{
          height: `${containerHeight}px`,
          width: "1920px",
          backgroundColor: "ivory",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: `${containerHeight - 100}px`,
            width: `${containerWidth - 100}px`,
            overflow: "auto",
          }}
        >
          <div
            className="container"
            style={{
              height: `100%`,
              width: `${3000}px`,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "red",
            }}
          >
            <section
              style={{
                height: `100%`,
                width: `${300}px`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "blue",
              }}
            >
              A
            </section>
            <section
              style={{
                height: `100%`,
                width: `${300}px`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "blue",
              }}
            >
              B
            </section>
            <section
              style={{
                height: `100%`,
                width: `${300}px`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "blue",
              }}
            >
              C
            </section>
          </div>
        </div>
      </section> */}
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"scrollable"}
          format={"column"}
          content={testRowContent}
          spacing={"space-evenly"}
          contentWidth={2300}
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
        background={'linear-gradient(-70deg, white, pink, lavender)'}
      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"unScrollable"}
          format={"column"}
          spacing={"space-evenly"}
          content={testRowContent}
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"scrollable"}
          format={"row"}
          spacing={"space-evenly"}
          content={testColContent}
          contentHeight={800}
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
        background={'linear-gradient(180deg, white, lavender)'}
      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"unScrollable"}
          spacing={"space-evenly"}
          format={"row"}
          content={testColContent}
          
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
        background={'linear-gradient(-30deg, deepskyblue, hotpink, violet)'}
      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"scrollable"}
          format={"walled"}
          content={testWallContent}
          contentWidth={3000}
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
        background={'linear-gradient(-5deg, rgba(20,20,20), rgba(50,50,50))'}

      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"scrollable"}
          format={"stacked"}
          content={testStackContent}
          contentHeight={1000}
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
        background={'linear-gradient(45deg, hotpink, violet)'}

      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"unScrollable"}
          format={"walled"}
          content={testWallContent}
        />
      </Layer>
      <Layer
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
        background={'linear-gradient(-45deg, violet, lavender)'}

      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"unScrollable"}
          format={"stacked"}
          content={testStackContent}
          contentHeight={3000}
        />
      </Layer>
      {/* <Layer 
        z={10}
        height={`${containerHeight}px`}
        width={`${containerWidth}px`}
      >
        <Container
          height={`${containerHeight - 100}px`}
          width={`${containerWidth - 100}px`}
          type={"unScrollable"}
          format={"grid"}
          content={
            <>
              <div style={{ border: '1px solid black', textAlign: 'center'}}>A</div>
              <div style={{ border: '1px solid black', textAlign: 'center'}}>B</div>
              <div style={{ border: '1px solid black', textAlign: 'center'}}>C</div>
            </>
          }
          contentHeight={3000}
        />
      </Layer> */}
    </main>
  );
};

/*
-Content
System integrator, MSSP. OT security
Services Included:

SOC, System intregrator, MSSP, OT security, AI/ML
SOC includes:
Email security (encryption, phishing, spam filtering etc)
Penetration testing and vulnerability scan, employee awareness training, dark web monitoring

Security Operations Center (SOC) Integration with Endpoint Detection and Response (EDR):

Our SOC is equipped with advanced threat detection capabilities and is seamlessly integrated with
Endpoint Detection and Response (EDR) solutions. This integration enables real-time monitoring,
analysis, and response to security incidents across your network and endpoints.
Email Security:

We provide robust email security solutions to safeguard your organization against phishing attacks,
malware, and other email-based threats. Our email security measures include advanced threat
detection, email encryption, and spam filtering to ensure the integrity and confidentiality of your email
communications.
Penetration Testing and Vulnerability Scanning:

Our team of certified security professionals conducts thorough penetration tests and vulnerability scans
to identify and address potential security weaknesses in your infrastructure, applications, and systems.
By proactively identifying vulnerabilities, we help mitigate the risk of cyber-attacks and data breaches.
Employee Awareness Training:

We offer comprehensive employee awareness training programs to educate your staff about cyber
security best practices, common threats, and how to recognize and respond to potential security
incidents. Empowering your employees with the knowledge and skills to identify and mitigate security
risks is crucial in strengthening your overall security posture.
Phishing Email Campaigns:

We conduct simulated phishing email campaigns to assess your employees&#39; susceptibility to phishing
attacks. By simulating real-world phishing scenarios, we can identify areas for improvement and tailor
targeted training to enhance your employees&#39; resilience against phishing threats.
Dark Web Monitoring for Breached Passwords:

Our dark web monitoring services continuously scan the dark web for any compromised credentials
associated with your organization. By monitoring for breached passwords, we help you proactively
mitigate the risk of unauthorized access and credential-based attacks.
*/
