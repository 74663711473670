import React from "react";
import { backgroundHandler } from "./styleFunctions";

export const Text = ({
  fontSize,
  textColor,
  text,
  stylizedText,
  continuous,
  verticalAlignment,
  horizontalAlignment,
  backgroundInfo,
  fontWeight,
}) => {
  const stylizeText = (text, stylizedText) => {
    let stylizedWords = [];
    let testText = text;
    stylizedText.forEach((word) => {
      if (testText.includes(word)) {
        const unStyledWord = (
          <span>{testText.substring(0, testText.indexOf(word))}</span>
        );
        const styledWord = (
          <span
            style={
              continuous
                ? {
                    WebkitTextFillColor: "transparent",
                  }
                : {
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    ...styleBackground(backgroundInfo),
                  }
            }
          >
            {word}
          </span>
        );
        stylizedWords = [...stylizedWords, unStyledWord, styledWord];
        testText = testText.substring(testText.indexOf(word) + word.length);
      }
    });
    stylizedWords = [...stylizedWords, <span>{testText}</span>];
    return stylizedWords;
  };

  const styleBackground = (backgroundInfo) => {
    const { coloringInfo, imageInfo } = backgroundInfo || {};
    const { backgroundColor, backgroundImage } = backgroundHandler(
      coloringInfo,
      imageInfo
    );
    return {
      backgroundImage: backgroundImage,
      backgroundColor: backgroundColor,
    };
  };
  return (
    <div
      className="text-container"
      style={{
        display: "table",
        height: "100%",
        width: "100%",
        fontWeight: fontWeight,
      }}
    >
      <p
        style={
          continuous
            ? {
                fontSize: fontSize,
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: textColor,
                display: "table-cell",
                verticalAlign: verticalAlignment,
                textAlign: horizontalAlignment,
                filter: "brightness(110%)",
                ...styleBackground(backgroundInfo),
              }
            : {
                fontSize: fontSize,
                color: textColor,
                display: "table-cell",
                verticalAlign: verticalAlignment,
                textAlign: horizontalAlignment,
                filter: "brightness(110%)",
              }
        }
      >
        {stylizeText(text, stylizedText)}
      </p>
    </div>
  );
};
