import { VideoPage } from "../../../components/layout/sections/VideoPage";
import logo from "../../../assets/images/the_solutioners_text_large.png";
import slogan from "../../../assets/images/the_solutioners_slogan_large.png";
import aboutVideo from "../../../assets/videos/Operational Technology Services.mp4";
import { sectionData } from "./Content/otSecurity.js";
import { ServiceSection } from "../../../components/sections/content/ServiceSection";

const headerHeight = 1000;
const mainHeight = 10000;
const title = "OT Security Services";
const summary =
  "This is a test video used to make sure that everything is working properly, This is a test video used to make sure that everything is working properly";
const gradient =
  "linear-gradient(30deg, rgba(255,230,255,1), rgba(10,120,240), rgba(10,50,240), rgba(180,0,250),  rgba(255, 80,170,1))";

export const OperationalTechnology = () => {
  return (
    <VideoPage
      title={"ot security"}
      content={<ServiceSection sectionData={sectionData}/>}
      headerHeight={headerHeight}
      slogan={slogan}
      logo={logo}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={title}
      videoCaption={sectionData.videoCaptions}
      gradient={gradient}
    />
  );
};
