import { NavLink } from "react-router-dom";
import { Accordion } from "../../../components/menus/accordion/Accordion";
import {
  contentStyles,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
} from "../../../components/sections/content/styles";
import { Container } from "../../../components/sections/content/Container";

const options = [
  {
    title: "Browser Based Antivirus",
    //Will also contain backgroundImage info
    //Also a button and more information as well
    contentHeight: "500px",
    text: `Advanced Browser Detection and Response (BDR)
and antivirus solutions that protect against web-based threats. Our tools provide real-time
scanning, phishing protection, and malicious site blocking to enhance your browsing security.`,
  },
  {
    title: "BCDR",
    contentHeight: "500px",
    text: `Business Continuity and Disaster Recovery planning to ensure your operations
can withstand and recover from unexpected disruptions. Our services include strategy
development, testing, and implementation of recovery plans.`,
  },
  {
    title: "Cloud Security",
    contentHeight: "500px",
    text: `Robust security solutions designed for cloud environments, ensuring
the confidentiality, integrity, and availability of your data. We provide cloud access security,
data protection, and compliance solutions to secure your cloud infrastructure.`,
  },
  {
    title: "Cybersecurity",
    contentHeight: "500px",
    text: `Comprehensive protection for your entire IT infrastructure, including
network security, endpoint protection, and threat intelligence. We tailor our approach to
safeguard your digital assets against a wide array of cyber threats.`,
  },
  {
    title: "Incident Response",
    contentHeight: "500px",
    text: `Rapid response and management of security incidents to
minimize damage and recovery time. Our team provides expert guidance and support to
effectively handle and mitigate breaches and attacks.`,
  },
  {
    title: "OT Security",
    contentHeight: "500px",
    text: `Protection for Operational Technology (OT) systems, including industrial
control systems and critical infrastructure. Our services focus on securing the technology that
supports essential operations and industrial processes.`,
  },
  {
    title: "Penetration Testing",
    contentHeight: "500px",
    text: `Simulated attacks to identify and address vulnerabilities in your
systems before real attackers can exploit them. We conduct comprehensive assessments to
strengthen your security defenses.`,
  },
  {
    title: "Risk Assessment",
    contentHeight: "500px",
    text: `A thorough evaluation of your organization’s risk exposure,
identifying vulnerabilities and potential threats. We deliver actionable insights to enhance
your risk management strategies and improve overall security posture.`,
  },
  {
    title: "System Integration",
    contentHeight: "500px",
    text: `Expert integration of diverse systems and technologies to
streamline operations and enhance efficiency. We ensure that all components work
seamlessly together, optimizing performance and security.`,
  },
];

export const ServicesContent = ({ sectionInfo }) => {
  // const generateContent = () => {
  //   let content = [];
  //   for (let i = 0; i < 5; i++) {
  //     const newContent = (
  //       <NavLink
  //         className={`card ${contentInfo.cardNames[i]}`}
  //         to={contentInfo.links[i]}
  //         style={{
  //           display: "table",
  //           backgroundImage: `url(${contentInfo.images[i]})`,
  //           backgroundSize: "400px 1000px",
  //         }}
  //         onClick={() => scrollToTop()}
  //       >
  //         <p
  //           style={{
  //             display: "table-cell",
  //             verticalAlign: "middle",
  //             textAlign: "center",
  //             filter: "drop-shadow(0px 0px 3px black)",
  //           }}
  //         >
  //           {contentInfo.cardNames[i]}
  //         </p>
  //       </NavLink>
  //     );
  //     content = [...content, newContent];
  //   }
  //   return content;
  // };

  const generateContent = () => {
    return options.map((option) => {
      return {
        title: option.title,
        height: option.contentHeight,
        style: superSectionStyles.whiteFlatBackground,
        content: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: [
              {
                type: "content",
                style: contentStyles.transparentBackgrounds.transparent,
                height: "100%",
                contentData: {
                  type: "subtitle",
                  text: option.text,
                  textColor: "black",
                  xAlignment: "center",
                  yAlignment: "middle",
                  stylizedText: [],
                  continuous: false,
                },
              },
            ],
          },
        ],
      };
    });
  };

  const generateContainers = (sectionInfo) => {
    return sectionInfo.map((section, index) => {
      return (
        <Container
          type={"superSection"}
          height={section.height}
          width={"100%"}
          style={section.style}
          index={index}
          contentData={section.contentData}
        />
      );
    });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {generateContainers(sectionInfo)}
      <section
        className="service-accordion-menu"
        style={{
          backgroundColor: "black",
          width: "100%",
          height: `${2300}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Accordion
          rowHeight={"200px"}
          width={"100%"}
          alwaysOpen={false}
          content={generateContent()}
        />
      </section>
    </section>
  );
};
