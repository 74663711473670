import logo from "../../../assets/images/the_solutioners_full_logo_white.png";
import diagonalPattern from "../../../assets/images/diagonal_pattern.png";
import { useRef } from "react";
import { NavButton } from "./NavButton";
import { NavLink } from "react-router-dom";

const logoStyle = {
  height: "100%",
  width: "20%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "33%",
  // overflow: "hidden",
};

export const Navbar = ({ btnInfo }) => {
  const currentPage = useRef("Home");
  const loadPage = (btnName) => {
    if (currentPage.current !== btnName) {
      if (btnName !== "About") {
        currentPage.current = btnName;
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 1000);
      }
    } else {
      window.scrollTo(0, 0); //This doesn't work, might have to use useScrollPosition instead, also it loads for the other pages that are not Home, which I don't want
    }
  };

  const generateNavButtons = () => {
    const navButtons = btnInfo.map((btn) => {
      return (
        <NavButton
          link={btn.link}
          name={btn.name}
          imageSource={btn.imageURL}
          imageDescription={btn.imageDescription}
          loadPage={loadPage}
          type={btn.type}
          menuInfo={btn.menuInfo !== undefined ? btn.menuInfo : undefined}
        ></NavButton>
      );
    });
    return navButtons;
  };
  return (
    <nav
      style={{
        position: "fixed",
        width: "100%",
        height: "4rem",
        top: "0px",
        backdropFilter: "blur(10px)  brightness(100%)",
        zIndex: 1000,
        borderBottom: "1px solid rgba(255,255,255,0.8)",
        filter: "drop-shadow(1px 1px 4px rgba(0,0,0, 0.24))",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundImage:
            "linear-gradient(90deg, rgba(1,1,1,1), rgba(1,1,1,0.9) 10%,  rgba(50,50,50, 0.85) 80%, rgba(50,50,50, 0.85) 90%, rgba(80,80,80, 0.65))",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "95%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div logo-spacer style={logoStyle}>
            <NavLink
              exact
              to={"/"}
              className="logo-container"
              style={{ height: "60%", width: "100%" }}
              onClick={() => loadPage("Home")}
            >
              <img src={logo} alt="Logo" style={{ height: "100%" }} />
            </NavLink>
          </div>
          <div className="nav-button-container" style={buttonContainerStyle}>
            {generateNavButtons()}
          </div>
        </div>
      </div>
    </nav>
  );
};
