import { HomeContent } from "../Content/Home/HomeContent";
import { VideoPage } from "../../components/layout/sections/VideoPage";
import mainLogo from "../../assets/images/the_solutioners_text_large.png";
import mainSlogan from "../../assets/images/the_solutioners_slogan_large.png";
import aboutVideo from "../../assets/videos/Introduction to The Solutioners.mp4";


const headerHeight = 1000;
const mainHeight = 4500;
const aboutTitle = "About Us";
const aboutSummary =
  `Welcome to our website, we are The Solutioners, a Canadian IT company that specializes in cybersecurity services.
   In today's digital age, protecting sensitive information is more crucial than ever. Our company is dedicated to
    providing you with top-notch security solutions to safeguard your data. Our team of experts utilizes the latest
     technologies to identify and mitigate potential threats. We offer a range of services, including network security,
      cloud security, and a browser-based antivirus software to help protect you from potential online threats. Our goal
       is to ensure your business remains secure and resilient. Partnering with us means you can focus on your core
       business activities without worrying about cyber threats. We provide continuous monitoring and support to keep
        your systems safe. At The Solutioners, you can trust us to be your reliable cybersecurity partners.
`
const homeGradient = 'linear-gradient(30deg, rgba(255,230,255,1), rgba(10,120,240), rgba(10,50,240), rgba(180,0,250),  rgba(255, 80,170,1))'
  //Put all of these into their separate JSON variables file

export const Home = () => {
  return (
    <VideoPage
      title={"home"}
      content={<HomeContent />}
      headerHeight={headerHeight}
      slogan={mainSlogan}
      logo={mainLogo}
      mainHeight={mainHeight}
      videoSource={aboutVideo}
      videoTitle={aboutTitle}
      videoCaption={aboutSummary}
      gradient={homeGradient}
    />
  );
};
