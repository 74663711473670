import { useState } from "react";
import { AccordionRow } from "./AccordionRow";

const initializeArray = (content) => {
  return content !== undefined && content.map(() => false);
};

export const Accordion = ({
  subMenu,
  content,
  rowHeight,
  width,
  alwaysOpen,
  level = 0,
}) => {
  const [active, setActive] = useState(
    alwaysOpen ? initializeArray(content) : null
  );

  const handleOnClick = (index) => {
    if (Array.isArray(active) && alwaysOpen) {
      setActive((prev) => {
        return prev.map((item, i) => (index === i ? !item : item));
      });
    } else {
      setActive((prev) => (prev === index ? null : index));
    }
  };

  const renderAccordion = (content) => {
    return content.map((item, index) => {
      return (
        <AccordionRow
          key={index}
          item={item}
          rowHeight={rowHeight}
          isOpen={alwaysOpen ? active[index] : active === index}
          onClick={() => handleOnClick(index)}
          alwaysOpen={alwaysOpen}
          level={subMenu ? level : 0}
        />
      );
    });
  };

  return (
    <div
      className="accordion-container"
      style={{
        width: width,
        // borderRadius: subMenu ? "0px" : "8px",
        overflow: "hidden",
        backdropFilter: subMenu ? "" : "blur(12px) brightness(70%)",
        filter: "brightness(110%)",
        border: subMenu ? "" : "1px solid rgba(255,255,255,0.1)",
        boxShadow: subMenu ? "" : "0px 0px 15px 1px rgba(0,0,0,0.25)",
      }}
    >
      {content !== undefined && renderAccordion(content)}
    </div>
  );
};
