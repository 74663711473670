import { backgroundHandler } from "./styleFunctions";

export const Button = ({
  name,
  text,
  height,
  width,
  backgroundInfo,
  style,
  onClick,
}) => {
  const styleBackground = (backgroundInfo) => {
    const { coloringInfo, imageInfo } = backgroundInfo || {};
    const { backgroundColor, backgroundImage } = backgroundHandler(
      coloringInfo,
      imageInfo
    );
    return {
      backgroundImage: backgroundImage,
      backgroundColor: backgroundColor,
    };
  };
  return (
    <button
      style={{
        boxShadow:
          style === "floating" ? "rgba(0, 0, 100, 0.2) 0px 0px 15px 1px" : "",
        borderRadius: "5px",
        border: "none",
        height: height,
        width: width,
        color: "white",
        ...styleBackground(backgroundInfo),
      }}
      onClick={onClick}
    >
      {text}
    </button>
  );
}; //Styles = Floating, flat, neumorphism, also this should be for multiple buttons as well with a spacer, that's what the content should be, an array of buttons
