import React from "react";
import { rng } from "../global/functions";
import { Container } from "./Container";
import { Layer } from "./Layer";

export const LAYOUT_TYPES = {
  DEFAULT: "default",
  LEFT_PANEL: "leftPanel",
  RIGHT_PANEL: "rightPanel",
  DOUBLE_PANEL: "doublePanel",
};

const generateRandomColor = () => {
  return `rgb(${[rng(255), rng(255), rng(255)]})`;
};

const containerHeight = 600;
const containerWidth = 400;

const defaultSectionStyle = {
  width: "inherit",
  color: "white",
  fontSize: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const scrollbarWidth = 17;

const testRowContent = (
  <>
    <section
      style={{
        height: `100%`,
        width: `${300}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
      }}
    >
      A
    </section>
    <section
      style={{
        height: `100%`,
        width: `${300}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
      }}
    >
      B
    </section>
    <section
      style={{
        height: `100%`,
        width: `${300}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "green",
      }}
    >
      C
    </section>
  </>
);

const testColContent = (
  <>
    <section
      style={{
        width: `100%`,
        height: `${100}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
      }}
    >
      A
    </section>
    <section
      style={{
        width: `100%`,
        height: `${100}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
      }}
    >
      B
    </section>
    <section
      style={{
        width: `100%`,
        height: `${100}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "green",
      }}
    >
      C
    </section>
  </>
);

const testWallContent = (
  <>
    <section
      style={{
        height: `100%`,
        width: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
      }}
    >
      A
    </section>
    <section
      style={{
        height: `100%`,
        width: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
      }}
    >
      B
    </section>
    <section
      style={{
        height: `100%`,
        width: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "green",
      }}
    >
      C
    </section>
  </>
);

const testStackContent = (
  <>
    <section
      style={{
        width: `100%`,
        height: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "blue",
      }}
    >
      A
    </section>
    <section
      style={{
        width: `100%`,
        height: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
      }}
    >
      B
    </section>
    <section
      style={{
        width: `100%`,
        height: `${1000}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "green",
      }}
    >
      C
    </section>
  </>
);

const generateContent = (number, width) => {
  let content = [];
  for (let i = 1; i <= number; i++) {
    const backgroundColor = generateRandomColor();
    const subcontent = (
      <section
        style={{
          height: "100%",
          width: `${width}px`,
          backgroundColor: backgroundColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i}
      </section>
    );
    content = [...content, subcontent];
  }
  return content;
};

// const generateContent = (type, number) => {
//   let subcontents = [];

//   for (let i = 1; i <= number; i++) {
//     const backgroundColor = generateRandomColor();
//     const subcontent = (
//       <section
//         style={{
//           height: "100%",
//           width: "100%",
//           backgroundColor: backgroundColor,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         {i}
//       </section>
//     );
//     subcontents = [...subcontents, subcontent];
//   }

//   let content;
//   if (type === "walled") {
//     content = (
//       <div
//         className="container"
//         style={{
//           height: `calc(100% - 100px)`,
//           width: `calc(100% - 100px)`,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           backgroundColor: "white",
//         }}
//       >
//         {subcontents}
//       </div>
//     );
//   } else if (type === "stacked") {
//     content = (
//       <div
//         className="container"
//         style={{
//           height: `calc(100% - 100px)`,
//           width: `calc(100% - 100px)`,
//           display: "flex",
//           justifyContent: "center",
//           flexDirection: "column",
//         }}
//       >
//         {subcontents}
//       </div>
//     );
//   } //Scrollables need fixed value dimensional units inOrder to work properly
//   else if (type === "scrollableWall") {
//     content = (
//       <div
//         className="container"
//         style={{
//           height: `200px`,
//           width: `400px`,
//           overflow: "auto",
//         }}
//       >
//         <div
//           className="content-area"
//           style={{
//             height: `100%`,
//             width: `${1000}px`,
//             display: "flex",
//           }}
//         >
//           {subcontents}
//         </div>
//       </div>
//     );
//   } else if (type === "scrollableStack") {
//     content = (
//       <div
//         className="container"
//         style={{
//           height: `200px`,
//           width: `400px`,
//           overflow: "auto",
//         }}
//       >
//         <div
//           className="content-area"
//           style={{
//             height: `${1000}px`,
//             width: `100%`,
//             display: "flex",
//             flexDirection: "column",
//           }}
//         >
//           {subcontents}
//         </div>
//       </div>
//     );
//   } //These ones are where the section sizes are dependent on the container for the scrollables, there will also be versions where the container size is dependent on the section sizes instead

//   return content;
// };

const generateSections = (number, type, format, containerWidth, subsectionCount) => {
  let sections = [];
  for (let i = 1; i <= number; i++) {
    const height = rng(400, 300)
    const horizontalPadding = 50
    const verticalPadding = 50
    const section = (
      <Layer
        z={10}
        height={`${height}px`}
        width={`100%`}
      >
        <Container
          height={`${height - verticalPadding}px`}
          width={`${containerWidth - horizontalPadding}px`}
          type={type}
          format={format}
          content={generateContent(subsectionCount, 300)}
          spacing={"space-evenly"}
          contentWidth={4000}
        />
      </Layer>
    );
    sections = [...sections, section];
  }
  return sections;
}; //Do this for all of them, but in a clean way. might need to make a method for all of them

export const Main = ({ layoutInfo }) => {
  const mainContent = generateSections(10, "scrollable", "column", 960, 25);
  const asideContent = generateSections(5, "scrollable", "column", 480, 100);
  const setLayout = (info) => {
    let gridTemplateColumns;
    let content;
    //Once I am done with all cases, make these into layers and containers
    switch (info.type) {
      case LAYOUT_TYPES.DEFAULT:
        gridTemplateColumns = "1fr";
        content = (
          <main
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {mainContent}
          </main>
        );
        break;
      case LAYOUT_TYPES.LEFT_PANEL:
        gridTemplateColumns = "1fr 2fr";
        content = (
          <>
            <aside
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {asideContent}
            </aside>
            <main
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {mainContent}
            </main>
          </>
        );
        break;
      case LAYOUT_TYPES.RIGHT_PANEL:
        gridTemplateColumns = "2fr 1fr";
        content = (
          <>
            <main
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {mainContent}
            </main>
            <aside
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {asideContent}
            </aside>
          </>
        );
        break;
      case LAYOUT_TYPES.DOUBLE_PANEL:
        gridTemplateColumns = "1fr 2fr 1fr";
        content = (
          <>
            <aside
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {asideContent}
            </aside>
            <main
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {mainContent}
            </main>
            <aside
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {asideContent}
            </aside>
          </>
        );
        break;
      default:
        gridTemplateColumns = "1fr";
        content = (
          <main
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {mainContent}
          </main>
        );
        break;
    }
    const layout = (
      <div
        style={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: gridTemplateColumns,
        }}
      >
        {content}
      </div>
    );
    return layout;
  };
  return <>{setLayout(layoutInfo)}</>;
};
