const parseGradientColors = (colorData) => {
  let colorString = "";
  colorData.forEach((data, i) => {
    if (colorData.length > 1 && i < colorData.length - 1) {
      colorString = colorString + ` ${data.color} ${data.stop}%,`;
    } else {
      colorString = colorString + ` ${data.color} ${data.stop}%`;
    }
  });
  return colorString;
};

const parseGradient = (gradientData) => {
  let gradient;
  if (gradientData.type === "linear") {
    const colors = parseGradientColors(gradientData.colorInfo);
    gradient = `linear-gradient(${gradientData.angle}deg, ${colors})`;
  }
  return gradient;
};

export const backgroundHandler = (coloringInfo, imageInfo) => {
  let backgroundColor = "transparent";
  let backgroundImage = "";
  if (coloringInfo) {
    if (coloringInfo.type === "color") {
      backgroundColor = coloringInfo.data;
    } else if (coloringInfo.type === "gradient") {
      backgroundImage = parseGradient(coloringInfo.data);
    }
    if (imageInfo) {
      if (imageInfo.bringToTop) {
        backgroundImage =
          backgroundImage === ""
            ? `url(${imageInfo.image})`
            : `url(${imageInfo.image}), ` + backgroundImage;
      } else {
        backgroundImage =
          backgroundImage === ""
            ? `url(${imageInfo.image})`
            : backgroundImage + `, url(${imageInfo.image})`;
      }
    }
  }
  return { backgroundColor: backgroundColor, backgroundImage: backgroundImage };
};

export const generatePattern = (patternInfo, borderRadius) => {
  return (
    <div
      className="pattern-container"
      style={{
        height: "100%",
        width: "100%",
        borderRadius: borderRadius,
        zIndex: -1,
        position: "absolute",
        overflow: "hidden",
        ...alignContent(patternInfo.direction),
      }}
    >
      <div
        className="background-pattern-overlay"
        style={{
          height: "100%",
          width: patternInfo.width,
          backgroundColor: patternInfo.color,
          maskImage: `url(${patternInfo.pattern})`,
          opacity: patternInfo.opacity + "%",
        }}
      ></div>
    </div>
  );
};

// export const generateGlowingBorder = (borderInfo) => {

// } //Do this later

export const generateFloor = (floorInfo) => {
  const { coloringInfo, imageInfo } = floorInfo.backgroundInfo || {};
  const { backgroundColor, backgroundImage } = backgroundHandler(
    coloringInfo,
    imageInfo
  );

  return (
    <div
      className="floor"
      style={{
        height: floorInfo.height,
        width: "100%",
        position: "absolute",
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage,
        bottom: "0px",
        zIndex: -2,
      }}
    ></div>
  );
};

export const centerItems = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const alignContent = (direction) => {
  let alignmentStyle = {};
  if (direction === "center") {
    alignmentStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
  } else if (direction === "top") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    };
  } else if (direction === "bottom") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
    };
  } else if (direction === "left") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    };
  } else if (direction === "right") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
    };
  } else if (direction === "topRight") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    };
  } else if (direction === "bottomRight") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    };
  } else if (direction === "topLeft") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    };
  } else if (direction === "bottomLeft") {
    alignmentStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-start",
    };
  }
  return alignmentStyle;
};

export const spaceContent = (spacing) => {
  return { display: "flex", alignItems: "center", justifyContent: spacing };
};

export const generateGlass = (borderRadius, blur, brightness) => {
  return {
    borderRadius: borderRadius,
    blur: blur,
    brightness: brightness,
  };
}; //Fix this to show translucent images and patterns later on, and for more advanced I can add surface gradients and shine animations as well

const parseShadow = (data) => {
  let shadowStyle = "";
  data.forEach((shadow, i) => {
    const shadowString = `${shadow.inset ? "inset" : ""} ${shadow.x}px ${
      shadow.y
    }px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`;
    if (data.length > 1 && i < data.length - 1) {
      shadowStyle = shadowStyle + `${shadowString}, `;
    } else {
      shadowStyle = shadowStyle + `${shadowString}`;
    }
  });
  return shadowStyle;
};

export const styleContainer = (styleInfo) => {
  const {
    direction,
    height,
    width,
    parallax,
    border,
    borderRadius,
    boxShadowInfo,
    blur,
    brightness,
    overflow,
    backgroundInfo,
    reflection,
    customProperties,
  } = styleInfo;

  const { coloringInfo, imageInfo } = backgroundInfo || {};
  const { backgroundColor, backgroundImage } = backgroundHandler(
    coloringInfo,
    imageInfo
  );
  let backdropFilter = "";
  if (blur !== null && blur !== undefined) {
    backdropFilter = backdropFilter + ` blur(${blur}px)`;
  }
  if (brightness !== null && brightness !== undefined) {
    backdropFilter = backdropFilter + ` brightness(${brightness}%)`;
  } //put backdrop filter into one object as well, same with border
  let style = {
    border: border
      ? `${border.thickness} ${border.style} ${border.color}`
      : "none",
    borderRadius: borderRadius,
    boxShadow: boxShadowInfo ? parseShadow(boxShadowInfo) : "",
    backdropFilter: backdropFilter,
    overflow: overflow,
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage,
    backgroundAttachment: parallax ? "fixed" : "scroll",
    WebkitBoxReflect: reflection,
    width: width,
    height: height,
    ...alignContent(direction),
    ...customProperties,
  };
  //transparent, divided, flat, glass
  return style;
}; //Also deal with parallax and reflections

export const generateStyle = (
  type,
  direction,
  styleType,
  overflow,
  height,
  width,
  parallax,
  customProperties = {}
) => {
  let styleInfo = {
    direction: type === "outer" && direction,
    height: height,
    width: width,
    parallax: parallax,
    overflow: overflow,
    customProperties,
  };
  if (styleType.includes("glass")) {
    if (styleType.includes("glass2")) {
      styleInfo = {
        ...styleInfo,
        ...generateGlass("8px", 16, 95),
      };
    } else if (styleType.includes("glass3")) {
      styleInfo = {
        ...styleInfo,
        ...generateGlass("8px", 12, 110),
      };
    } else if (styleType.includes("glass4")) {
      styleInfo = {
        ...styleInfo,
        ...generateGlass("0px", 50, 100),
      };
    } else {
      styleInfo = {
        ...styleInfo,
        ...generateGlass("7px", 16, 100),
      };
    }
    if (styleType.includes("border")) {
      styleInfo = {
        ...styleInfo,
        border: {
          thickness: "1px",
          style: "solid",
          color: "rgba(255, 255, 255, 0.1)",
        },
      };
    }
  } else if (styleType.includes("flat")) {
    if (styleType.includes("roundedRightCorners")) {
      styleInfo = {
        ...styleInfo,
        border: "none",
        borderRadius: "0px 7px 7px 0px",
        brightness: 100,
      };
    } else if (styleType.includes("roundedLeftCorners")) {
      styleInfo = {
        ...styleInfo,
        border: "none",
        borderRadius: "7px 0px 0px 7px",
        brightness: 100,
      };
    } else {
      styleInfo = {
        ...styleInfo,
        border: "none",
        borderRadius: "0px",
        brightness: 100,
      };
    }
  } else if (styleType.includes("transparent")) {
    styleInfo = {
      ...styleInfo,
      border: "0px solid transparent",
    };
  }
  if (styleType.includes("shadow")) {
    styleInfo = {
      ...styleInfo,
      boxShadowInfo: [
        { x: 0, y: 0, blur: 15, spread: 0, color: "rgba(0,0,0, 0.33)" },
      ],
    };
  }
  if (styleType.includes("reflect")) {
    styleInfo = {
      ...styleInfo,
      reflection:
        "below 0px linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0) 70%, rgba(0,0,0,0.8))",
    };
  }
  return styleInfo;
}; //This is starting to become more and more like tailwind, I might have to start using it at that point

//Will work on section, subsection, content, button etc
//One method to generate styles and another method to generate the actual HTML (so basically separate css and html functions)

//Background styles: Gradient (Standard gradient), Glossy Floor, Pattern, Image/Faded Image,  Video (Later), Animated, like animated gradients and pattern (later)
//Next steps, background styles, title style, gradient text, buttons, organize the window part, and break the function into multiple functions and organizing this and putting all styles into one folder for use
