import { generateStyle, spaceContent } from "./styleFunctions";
import testBackgroundPattern from "../../../assets/images/diagonal_pattern.png";
import testBackgroundImage from "../../../assets/images/Cloud_Security_Background.jpg";

//Background gradients, images and patterns

export const transparent = {
  coloringInfo: {
    type: "color",
    data: "transparent",
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

export const purpleBlueGradient = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 80,
      colorInfo: [
        { color: "rgba(235, 15, 245, 1)", stop: "0" },
        { color: "rgba(165, 15, 255, 1)", stop: "50" },
        { color: "rgba(55, 5, 255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteGlass60 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 80,
      colorInfo: [
        { color: "rgba(245,250,255, 0.6)", stop: "0" },
        { color: "rgba(255,255,255, 0.6)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteGlass25 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 80,
      colorInfo: [
        { color: "rgba(245,250,255, 0.25)", stop: "0" },
        { color: "rgba(255,255,255, 0.25)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const blackGlass10 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(0, 0, 0, 0.1)", stop: "0" },
        { color: "rgba(5, 5, 15, 0.1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const blackGlass50 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(0, 0, 0, 0.5)", stop: "0" },
        { color: "rgba(5, 5, 15, 0.5)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteDiagonalGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(245,250,255, 0.5)", stop: "0" },
        { color: "rgba(210,215,255,0.5)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 10,
    color: "white",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const blackDiagonalGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(10, 10, 20, 1)", stop: "0" },
        { color: "rgba(30, 30, 40, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 10,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const whiteBlackGlass = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(245,250,255, 0.95)", stop: "0" },
        { color: "rgba(210,220,255, 0.1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFlat = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 165,
      colorInfo: [
        { color: "rgba(245,250,255, 1)", stop: "0" },
        { color: "rgba(210,220,255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFlat2 = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 135,
      colorInfo: [
        { color: "rgba(252, 253, 255, 1)", stop: "0" },
        { color: "rgba(210, 215, 255, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const blackFlat = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(10, 10, 20, 1)", stop: "0" },
        { color: "rgba(30, 30, 40, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: null,
};

const whiteFadedImage = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 165,
      colorInfo: [
        { color: "rgba(245,250,255, 0.99)", stop: "0" },
        { color: "rgba(210,220,255, 0.95)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: {
    image: testBackgroundImage,
  },
  floorInfo: null,
};

const clearImage = {
  coloringInfo: {
    type: "color",
    data: "transparent",
  },
  patternInfo: null,
  imageInfo: {
    image: testBackgroundImage,
  },
  floorInfo: null,
};

const greyLightDiagonal = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(50, 50, 70, 1)", stop: "0" },
        { color: "rgba(80, 80, 100, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 100,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const greyDarkDiagonal = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(10, 10, 25, 1)", stop: "0" },
        { color: "rgba(30, 30, 45, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 100,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const whiteFloor = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 0,
      colorInfo: [
        { color: "rgba(0,0,0, 1)", stop: "0" },
        { color: "rgba(10,10,30, 1)", stop: "100" },
      ],
    },
  },
  patternInfo: null,
  imageInfo: null,
  floorInfo: {
    height: "50%",
    backgroundInfo: {
      coloringInfo: {
        type: "gradient",
        data: {
          type: "linear",
          angle: 180,
          colorInfo: [
            { color: "rgba(245,250,255, 1)", stop: "0" },
            { color: "rgba(210,220,255, 1)", stop: "100" },
          ],
        },
      },
    },
  },
};

const clearBlackDiagonal = {
  coloringInfo: {
    type: "color",
    data: "transparent",
  },
  patternInfo: {
    pattern: testBackgroundPattern, //Turn this into it's own object called pattern Info
    opacity: 100,
    color: "black",
    width: "100%",
    direction: "center",
  },
  imageInfo: null,
  floorInfo: null,
};

const blackDiagonalBackground = {
  coloringInfo: {
    type: "gradient",
    data: {
      type: "linear",
      angle: 125,
      colorInfo: [
        { color: "rgba(1, 1, 1, 1)", stop: "0" },
        { color: "rgba(15,15,25, 1)", stop: "100" },
      ],
    },
  },
  imageInfo: {
    bringToTop: true,
    image: testBackgroundPattern,
  },
  floorInfo: null,
};

//Style Packages

export const superSectionStyles = {
  blackDiagonalBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat",
        "hidden",
        "100%",
        "100%",
        true
      ),
      backgroundInfo: blackDiagonalBackground,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "90%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  parallaxImageBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "hidden",
        "100%",
        "100%",
        true,
        { zIndex: 1 }
      ),
      backgroundInfo: clearImage,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "90%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlat,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  whiteFlatBackground2: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "visible",
        "100%",
        "100%",
        false,
        { zIndex: 2 }
      ),
      backgroundInfo: whiteFlat2,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "100%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
  glossyFloorBackground: {
    outer: {
      ...generateStyle(
        //Might need to change this
        "outer",
        "center",
        "flat-shadow",
        "hidden",
        "100%",
        "100%",
        true
      ),
      backgroundInfo: whiteFloor,
    },
    inner: {
      ...generateStyle(
        "inner",
        null,
        "transparent",
        "visible",
        "90%",
        "90%",
        false
      ),
      backgroundInfo: transparent,
    },
  },
};

export const subsectionStyles = {
  transparentBackgrounds: {
    transparent: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false,
          spaceContent("space-evenly")
        ),
        backgroundInfo: transparent,
      },
    },
    transparentCard: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false,
          spaceContent("space-evenly")
        ),
        backgroundInfo: transparent,
      },
    },
  },
  window: {
    windowAside: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false,
          spaceContent("center")
        ),
        backgroundInfo: transparent,
      },
    },
    windowMain: {
      outer: {
        ...generateStyle(
          "outer",
          "right",
          "glass2",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: whiteBlackGlass,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat roundedRightCorners",
          "visible",
          "100%",
          "100%",
          false,
          spaceContent("center")
        ),
        backgroundInfo: transparent,
      },
    },
  },
};

export const contentStyles = {
  transparentBackgrounds: {
    transparent: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackDiagonals: {
      outer: null,
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: clearBlackDiagonal,
      },
    },
    transparentCard: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
  flatBackgrounds: {
    doubleFlatLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "99%",
          "100%",
          false,
          { color: "white", zIndex: "10" }
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "hidden",
          "105%",
          "98%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
    },
    doubleFlatDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "99%",
          "100%",
          false,
          { color: "white", zIndex: "10" }
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "hidden",
          "105%",
          "98%",
          false
        ),
        backgroundInfo: blackFlat,
      },
    },
    darkCard: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: blackFlat,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
};

export const sectionStyles = {
  transparentBackgrounds: {
    transparent: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "transparent",
          "visible",
          "100%",
          "90%",
          false
        ),
        backgroundInfo: transparent,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
  glassBackgrounds: {
    whiteDiagonalGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass4-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteDiagonalGlass,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackDiagonalGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass4-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackDiagonalGlass,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    whiteGlass60: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass3-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteGlass60,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    whiteGlass25: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass3-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteGlass25,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackGlass50: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass2-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass50,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    blackGlass10: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "glass2-border-shadow-reflection",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass10,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    //Make reflective versions as well
  },
  flatBackgrounds: {
    flatWhite: {
      outer: {
        ...generateStyle(
          //Might need to change this
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    fadedWhite: {
      outer: {
        ...generateStyle(
          //Might need to change this
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: whiteFadedImage,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "transparent",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    overlappingFlatCenterLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "101%",
          "90%",
          false
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "102%",
          "97%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
    },
    overlappingFlatCenterDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "101%",
          "90%",
          false
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "102%",
          "97%",
          false
        ),
        backgroundInfo: blackFlat,
      },
    },
  }, //Mainly double flat
  windowBackgrounds: {
    darkGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "right",
          "glass2-border-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass50,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat roundedRightCorners",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
    lightGlass: {
      outer: {
        ...generateStyle(
          "outer",
          "right",
          "glass2-border-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: blackGlass10,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "glass3",
          "visible",
          "100%",
          "100%",
          false
        ),
        backgroundInfo: transparent,
      },
    },
  },
  frameBackgrounds: {
    centerDiagonalLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "95%",
          "95%",
          false
        ),
        backgroundInfo: whiteFlat,
      },
    },
    cornerDiagonalLight: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyLightDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "100%",
          "100%",
          false,
          { top: "10px", left: "10px" }
        ),
        backgroundInfo: whiteFlat,
      },
    },
    centerDiagonalDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "95%",
          "95%",
          false
        ),
        backgroundInfo: blackFlat,
      },
    },
    cornerDiagonalDark: {
      outer: {
        ...generateStyle(
          "outer",
          "center",
          "flat-shadow",
          "visible",
          "90%",
          "90%",
          false
        ),
        backgroundInfo: greyDarkDiagonal,
      },
      inner: {
        ...generateStyle(
          "inner",
          null,
          "flat-shadow",
          "visible",
          "100%",
          "100%",
          false,
          { top: "10px", left: "10px" }
        ),
        backgroundInfo: blackFlat,
      },
    },
  },
};

/* 
// For SuperSection, background with image, pattern and or gradient, and can be parallax
// Put all of the superSections into one package(including floor), all content styles into one package, windows into one package, all double Matte into one package as well

 */
