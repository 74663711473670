import React from "react";
import { backgroundHandler, centerItems } from "./styleFunctions";
import { Card } from "./Card";
import { contentStyles } from "./styles";

export const Grid = ({ noOfColumns, data, style, itemHeight, itemWidth }) => {
  //Add nested level later on for bug fixing purposes
  const generateGrid = (data) => {
    return data.map((item, index) => {
      return (
        <div
          className="grid-item-spacer"
          style={{
            height: "100%",
            width: "100%",
            color: "white",
            ...centerItems,
          }}
        >
          <div
            className="grid-item-container"
            style={{
              height: "90%",
              width: "90%",
              color: "white",
              ...centerItems,
            }}
          >
            <Card
              type={item.type}
              content={item.content}
              width={"100%"}
              index={index}
              style={style}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <section
      className="grid"
      style={{
        height: "100%", //Will be determined by style
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto ".repeat(noOfColumns),
      }}
    >
      {generateGrid(data)}
    </section>
  );
};
