import { Accordion } from "../../menus/accordion/Accordion";
import { TabMenu } from "../../menus/TabMenu";
import { Button } from "./Button";
import { Card } from "./Card";
import { Grid } from "./Grid";
import {
  centerItems,
  generateFloor,
  generatePattern,
  styleContainer,
} from "./styleFunctions";
import { purpleBlueGradient } from "./styles";
import { Text } from "./Text";

export const renderContent = (content) => {
  if (content.type === "title") {
    return (
      <Text
        continuous={content.continuous}
        text={content.text}
        textColor={content.textColor}
        stylizedText={content.stylizedText}
        verticalAlignment={content.yAlignment}
        horizontalAlignment={content.xAlignment}
        backgroundInfo={purpleBlueGradient}
        fontSize={"3cqw"}
        fontWeight={600}
      />
    );
  } else if (content.type === "subtitle") {
    return (
      <Text
        continuous={content.continuous}
        text={content.text}
        textColor={content.textColor}
        stylizedText={content.stylizedText}
        verticalAlignment={content.yAlignment}
        horizontalAlignment={content.xAlignment}
        backgroundInfo={purpleBlueGradient}
        fontSize={"1.75cqw"}
        fontWeight={0}
      />
    );
  } else if (content.type === "paragraph") {
    return (
      <Text
        continuous={content.continuous}
        text={content.text}
        textColor={content.textColor}
        stylizedText={content.stylizedText}
        verticalAlignment={content.yAlignment}
        horizontalAlignment={content.xAlignment}
        backgroundInfo={purpleBlueGradient}
        fontSize={"0.95cqw"}
        fontWeight={0}
      />
    );
  } else if (content.type === "image") {
    return (
      <figure
        style={{
          height: "100%", //Will be determined by style
          width: "100%",
          margin: "0px",
          ...centerItems,
        }}
      >
        <img
          src={content.src}
          alt={content.description}
          style={{ height: "100%", width: "100%", objectFit: "contain" }} //will handle contain/cover later
        />
      </figure>
    );
  } else if (content.type === "button") {
    return (
      <div
        className="button-container"
        style={{
          height: "100%", //Will be determined by style
          width: "100%",
          ...centerItems,
        }}
      >
        <Button
          name={content.name}
          text={content.text}
          height={content.buttonHeight}
          width={content.buttonWidth}
          onClick={content.onClick}
          style={content.style}
          backgroundInfo={content.backgroundInfo}
        />
      </div>
    );
  } else if (content.type === "card") {
    return (
      <Card
        type={"content"}
        width={"90%"}
        content={content.content}
        style={content.style}
        index={content.index}
      />
    );
  } else if (content.type === "grid") {
    return (
      <Grid
        noOfColumns={content.numberOfColumns}
        data={content.contentData}
        style={content.style}
      />
    );
  } else if (content.type === "accordion") {
    return (
      <div
        className="section-container"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          overflow: "hidden",
        }}
      >
        <Accordion
          subMenu={false}
          content={content.accordionContent}
          width={"100%"}
          alwaysOpen={content.alwaysOpen}
        />
      </div>
    );
  } else if (content.type === "tabMenu") {
    return <TabMenu height={content.height} width={content.width} contentData={content.contentData}/>;
  }
};

const spaceContainer = (type, items, index) => {
  if (type === "section" && items === 2) {
    if (index === 0) {
      return "30%";
    } else {
      return "70%";
    }
  } else if (type === "subsection" || type === "content") {
    return "100%";
  } else {
    return 100 / items + "%";
  }
};

export const Container = ({
  type,
  height,
  width,
  style,
  contentData,
  index,
  divideLine,
  nestedLevel = 0,
  ratio,
}) => {
  const renderSubContainer = (type, data) => {
    if (Array.isArray(data)) {
      return data.map((subContainer, index) => {
        return (
          <Container
            type={subContainer.type}
            height={subContainer.height}
            width={spaceContainer(type, data.length, index)}
            style={subContainer.style}
            contentData={subContainer.contentData}
            divideLine={subContainer.divideLine}
            index={index}
            nestedLevel={nestedLevel + 1}
            key={index}
          />
        );
      });
    } else {
      return renderContent(data);
    }
  };

  const renderContainer = (type, height, width, style, index) => {
    let inner = <>{renderSubContainer(type, contentData)}</>;
    let outer = <>{inner}</>;
    let spacer = <>{outer}</>;
    if (style.inner !== undefined && style.inner !== null) {
      const { patternInfo, floorInfo } = style.inner.backgroundInfo || {};
      inner = (
        <div
          className={`${type}-${index}-inner-container level-${nestedLevel}`}
          style={{
            flexDirection:
              type === "superSection" || type === "section" ? "row" : "column",
            ...(type === "subsection" ? {} : centerItems),
            ...styleContainer(style.inner),
          }}
        >
          {renderSubContainer(type, contentData)}
          {patternInfo &&
            generatePattern(patternInfo, style.inner.borderRadius)}
          {floorInfo && generateFloor(floorInfo)}
        </div>
      );
    }
    if (style.outer !== undefined && style.outer !== null) {
      const { patternInfo, floorInfo } = style.outer.backgroundInfo || {};
      outer = (
        <section
          className={`${type}-${index}-outer-container level-${nestedLevel}`}
          style={styleContainer(style.outer)}
        >
          {inner}
          {patternInfo &&
            generatePattern(patternInfo, style.outer.borderRadius)}
          {floorInfo && generateFloor(floorInfo)}
        </section>
      );
    }
    if (
      style.outer !== undefined ||
      style.outer !== null ||
      style.inner !== undefined ||
      style.inner !== null
    ) {
      spacer = (
        <div
          className={`${type}-${index}-spacer level-${nestedLevel}`}
          style={{
            height: height,
            width: width,
            borderRight:
              divideLine !== undefined && divideLine !== null
                ? divideLine
                : "none",
            ...centerItems,
          }}
        >
          {outer}
        </div>
      );
    }
    return spacer;
  };

  return renderContainer(type, height, width, style, index);
};

/* 
// Outer style will have a direction and won't be centered necessarily, so it will have a direction
// Inner style will decide how the items inside are spaced, it will all be centered in the middle but the flexDirection will
// be decided by the style, with section and supersection having it be rows rather than columns. Will not have a direction
// Floor maker as well for the inner gradient
// Fix the content data to be compatible with this
// Fix the styles to be compatible with this
// Organize all the styles into a styling function which generates it based on the name and clean up and organize all of the code
// Deal with headers/content styling
// Then deal with text formatting
Make multiple different colored gradients for use
Change the video caption titles as well
Logo ideas: Cybersecurity: Lock, Antivirus: Shield in the middle of a globe, Cloud security: Cloud with Lock, System Integrator: Gears + Wires, OT: Robot arm
Then I can play around with button sizes and also decide which sections get to be animated via the className, Deal with button styling as well
Later, make the icon symbols for the navbar and cybersecurity icons translucent for the headers and have them float and be parallax
Make the form into a 2 row column
Deal with section animations (appearing and disappearing)
Deal with subsection animations (floating images e.g)
 */
