
export const Header = ({ height, title, logo, slogan, gradient }) => {
  return (
    <header
      className={`${title}-header`}
      style={{
        width: "100%",
        height: `${height}px`,
        // backgroundImage: `url(${backgroundImage})`,
        backgroundImage: gradient,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "1920px 1080px",
        fontSize: "250px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        boxShadow: "inset 0px -5px 8px 1px rgba(0,0,0,0.1)",
        position: "relative",
        zIndex: "5",
      }}
    >
      <div
        className="logo-container"
        style={{
          width: "986px",
          height: "230px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {title === 'home' && <div
          className="icon-container"
          style={{
            width: "210px",
            height: "230px",
          }}
        >
          <div className="header-logo-icon">
            <div
              className="arrow-right"
              style={{ position: "relative", top: "8px", left: "20px" }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  height: "70px",
                  width: "56px",
                  transform: "skew(-45deg)",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "white",
                  height: "70px",
                  width: "56px",
                  transform: "skew(45deg)",
                }}
              ></div>
            </div>
            <div
              className="arrow-left"
              style={{ position: "relative", top: "-62px", left: "100px" }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  height: "70px",
                  width: "56px",
                  transform: "skew(45deg)",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "white",
                  height: "70px",
                  width: "56px",
                  transform: "skew(-45deg)",
                }}
              ></div>
            </div>
          </div>
        </div>} 
{/* Have larger versions of the navbar button icons for the services and contact icons */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="header-logo"
            src={logo}
            alt="Logo"
            style={{ width: "100%", height: "100%" }}
          />
          <img
            className="header-slogan"
            src={slogan}
            alt="Logo"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <div className={`halftone ${title}`}></div>
    </header>
  );
};
