import {
  contentStyles,
  sectionStyles,
  subsectionStyles,
  superSectionStyles,
} from "../../../components/sections/content/styles";

const section1Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "100%",
    contentData: {
      type: "subtitle",
      text: `In today’s digital landscape, robust cybersecurity is essential for the success and continuity of any
business. As cyber threats become increasingly frequent and sophisticated, safeguarding sensitive data
and implementing comprehensive security measures are paramount.`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["cybersecurity", "cyber threats"],
      continuous: false,
    },
  },
];

const section2Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "title",
      text: `Who are we?`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "100%",
    contentData: {
      type: "subtitle",
      text: `We are a leading Canadian IT company committed to delivering cutting-edge cybersecurity solutions and
technologies. Our mission is to provide you with the most advanced and effective security services to
protect your business from evolving digital threats. With a focus on innovation and excellence, we
leverage the latest advancements in cybersecurity to safeguard your sensitive information and ensure
your operations remain secure.`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["Canadian IT company", "cyber threats"],
      continuous: false,
    },
  },
];

const section3Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "title",
      text: `What do we do?`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "subtitle",
      text: `Our company specializes in a wide variety of cybersecurity solutions, such as Threat Detection,
        Cloud security, Operation Technology Security, SOC Services, Employee Awareness Training, System Integration Services,
         and many more services`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["wide variety"],
      continuous: false,
    },
  },
];

const section4Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "title",
      text: `Why choose us?`,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "50%",
    contentData: {
      type: "subtitle",
      text: `Although we are a new entrant in the cybersecurity field, our impact is already being felt across the
industry. Our team is exceptionally knowledgeable about the latest technological advancements,
including artificial intelligence, and how they will shape the future of IT and cybersecurity.
We pride ourselves on our ability to harness cutting-edge technologies to offer innovative solutions
tailored to your specific needs. Our team of experienced IT professionals and cybersecurity analysts is
dedicated to ensuring the utmost protection for your sensitive data and business information.
Partner with us to experience a proactive and forward-thinking approach to cybersecurity. Join the
growing number of businesses that have trusted us to secure their digital assets and navigate the
evolving threat landscape with confidence.
    `,
      textColor: "white",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [
        "well versed",
        "latest technologies",
        "AI",
        "IT professionals",
        "guarantee the protection",
      ],
      continuous: false,
    },
  },
];

const section5Content = [
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "15%",
    contentData: {
      type: "title",
      text: `Our Valued Partnerships`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "bottom",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "35%",
    contentData: {
      type: "subtitle",
      text: `At The Solutioners, we believe that collaboration is key to achieving exceptional results. We are
proud to work with a diverse array of esteemed business partners who share our commitment to
excellence in cybersecurity. Each partnership enhances our ability to deliver top-tier solutions and
services tailored to meet the unique needs of our clients.`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["wide variety"],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "15%",
    contentData: {
      type: "title",
      text: `Explore Our Network`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: [],
      continuous: false,
    },
  },
  {
    type: "content",
    style: contentStyles.transparentBackgrounds.transparent,
    height: "35%",
    contentData: {
      type: "subtitle",
      text: `We invite you to explore our network of trusted partners through the slideshow below. These
partnerships are a testament to our dedication to providing comprehensive, cutting-edge cybersecurity
solutions and our ability to collaborate effectively within the industry. Together, we are setting new
standards in cybersecurity and driving innovation forward.`,
      textColor: "black",
      xAlignment: "center",
      yAlignment: "middle",
      stylizedText: ["wide variety"],
      continuous: false,
    },
  },
];

export const homeContent = [
  {
    height: "500px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section1Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "500px",
    style: superSectionStyles.blackDiagonalBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section2Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "700px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section3Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "700px",
    style: superSectionStyles.blackDiagonalBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section4Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
  {
    height: "1000px",
    style: superSectionStyles.whiteFlatBackground,
    contentData: [
      {
        type: "section",
        height: "100%",
        style: sectionStyles.transparentBackgrounds.transparent,
        contentData: [
          {
            type: "subsection",
            height: "100%",
            style: subsectionStyles.transparentBackgrounds.transparent,
            contentData: section5Content,
          },
        ],
        divideLine: "",
      },
    ],
  },
];
