import "./assets/styles/App.scss";
import { Navbar } from "./components/sections/navbar/Navbar.jsx";
import { Home } from "./containers/Pages/Home";
import { Route, Routes } from "react-router-dom";
import { About } from "./containers/Pages/About";
import { Test } from "./containers/Pages/Test";
import { Services } from "./containers/Pages/Services";
import { Contact } from "./containers/Pages/Contact";
import { LoadingScreen } from "./components/LoadingScreen";
import { useEffect, useState } from "react";
import homeImage from "../src/assets/images/home.png";
import aboutImage from "../src/assets/images/about.png";
import serviceImage from "../src/assets/images/service.png";
import contactImage from "../src/assets/images/contact.png";
import { Cybersecurity } from "./containers/Pages/Services/Cybersecurity";
import { SystemIntegrator } from "./containers/Pages/Services/SystemIntegrator";
import { CloudSecurity } from "./containers/Pages/Services/CloudSecurity";
import { Antivirus } from "./containers/Pages/Services/Antivirus";
import { OperationalTechnology } from "./containers/Pages/Services/OperationalTechnology";
import { BCDR } from "./containers/Pages/Services/BCDR.jsx";
import { PenetrationTesting } from "./containers/Pages/Services/PenetrationTesting.jsx";
import { RiskAssessment } from "./containers/Pages/Services/RiskAssessment.jsx";
import { IncidentResponse } from "./containers/Pages/Services/IncidentResponse.jsx";

const btnInfo = [
  { name: "Home", link: "/", imageURL: homeImage, type: "button" },
  { name: "About", link: "/", imageURL: aboutImage, type: "button" },
  {
    name: "Services",
    link: "services",
    imageURL: serviceImage,
    type: "menu",
    menuInfo: [
      {
        name: "Browser Based Antivirus",
        link: "services/browser_based_antivirus",
      },
      { name: "BCDR", link: "services/bcdr" },
      { name: "Cybersecurity", link: "services/cybersecurity" },
      { name: "Cloud Security", link: "services/cloud_security" },
      { name: "Incident Response", link: "services/incident_response" },
      { name: "OT security", link: "services/ot_security" },
      { name: "Penetration Testing", link: "services/penetration_testing" },
      { name: "Risk Assessment", link: "services/risk_assessment" },
      { name: "System Integrators", link: "services/system_integrators" },
    ],
  },
  { name: "Contact", link: "contact", imageURL: contactImage, type: "button" },
  /* ,  {name: 'Test', link: 'test'} */
];

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 600);
  }, []);
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className="App">
      <Navbar btnInfo={btnInfo} />
      <Routes>
        <Route index element={<Home />} />
        {/* <Route path='about' element={<About />} /> */}
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        {/* <Route path='test' element={<Test />} /> */}
        <Route path="services/cybersecurity" element={<Cybersecurity />} />
        <Route
          path="services/system_integrators"
          element={<SystemIntegrator />}
        />
        <Route path="services/cloud_security" element={<CloudSecurity />} />
        <Route
          path="services/browser_based_antivirus"
          element={<Antivirus />}
        />
        <Route
          path="services/ot_security"
          element={<OperationalTechnology />}
        />
        <Route path="services/bcdr" element={<BCDR />} />{" "}
        <Route
          path="services/risk_assessment"
          element={<RiskAssessment />}
        />{" "}
        <Route
          path="services/penetration_testing"
          element={<PenetrationTesting />}
        />{" "}
        <Route
          path="services/incident_response"
          element={<IncidentResponse />}
        />
      </Routes>
    </div>
  );
}

export default App;
