import React from "react";
import logo from "../../assets/images/the_solutioners_text_large.png";
import slogan from "../../assets/images/the_solutioners_slogan_large.png";

export const Logo = ({ size }) => {
  const scale = (def) => {
    return `${(def / 100) * size}px`;
  };
  return (
    <div
      className="logo-container"
      style={{
        width: scale(986),
        height: scale(230),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="icon-container"
        style={{
          width: scale(210),
          height: scale(230),
          transform: `translateX(${size - (10000/size) + ((3000 * size)/(size*size))}%)`,
        }}
      >
        <div className="header-logo-icon">
          <div
            className="arrow-right"
            style={{ position: "relative", top: scale(8), left: scale(20) }}
          >
            <div
              style={{
                backgroundColor: "white",
                height: scale(70),
                width: scale(56),
                transform: "skew(-45deg)",
              }}
            ></div>
            <div
              style={{
                backgroundColor: "white",
                height: scale(70),
                width: scale(56),
                transform: "skew(45deg)",
              }}
            ></div>
          </div>
          <div
            className="arrow-left"
            style={{
              position: "relative",
              top: scale(-62),
              left: scale(100),
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                height: scale(70),
                width: scale(56),
                transform: "skew(45deg)",
              }}
            ></div>
            <div
              style={{
                backgroundColor: "white",
                height: scale(70),
                width: scale(56),
                transform: "skew(-45deg)",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          className="header-logo"
          src={logo}
          alt="Logo"
          style={{ width: "100%", height: "100%" }}
        />
        <img
          className="header-slogan"
          src={slogan}
          alt="Logo"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
};
