import { ScrollingGallery } from "../../../components/layout/sections/ScrollingGallery";
import { Container } from "../../../components/sections/content/Container";
import testImage from "../../../assets/images/test_section_picture.png";
import {} from "../../../components/sections/content/styles";
import { homeContent } from "./Content";
import { TabMenu } from "../../../components/menus/TabMenu";
import { Accordion } from "../../../components/menus/accordion/Accordion";
import { Numbers } from "../../../components/sections/content/Numbers";
import { Scroller } from "../../../components/sections/content/Scroller";

// const testContent = [
//   {
//     type: "content",
//     style: contentStyle,
//     height: "10%",
//     contentData: {
//       type: "title",
//       text: "title",
//       textColor: "white",
//       xAlignment: "center",
//       yAlignment: "middle",
//       stylizedText: [],
//     },
//   },
//   {
//     type: "content",
//     style: contentStyle,
//     height: "10%",
//     contentData: {
//       type: "subtitle",
//       text: "This is a test heading to be used for this section. This will test whether or not gradient texts work",
//       textColor: "white",
//       xAlignment: "center",
//       yAlignment: "middle",
//       stylizedText: [],
//     },
//   },
//   {
//     type: "content",
//     style: contentStyle,
//     height: "10%",
//     contentData: {
//       type: "paragraph",
//       text: "This is a test caption to be used for this paragraph",
//       textColor: "white",
//       xAlignment: "center",
//       yAlignment: "middle",
//       stylizedText: [],
//     },
//   },
//   {
//     type: "content",
//     height: "10%",
//     style: contentStyle,
//     contentData: {
//       type: "button",
//       name: "button",
//       text: "Click Me \u2192",
//       buttonHeight: "30px",
//       buttonWidth: "120px",
//       style: "floating",
//       onClick: () => console.log("clicked"),
//     },
//   },
//   {
//     type: "content",
//     height: "10%",
//     style: contentStyle,
//     contentData: {
//       type: "image",
//       src: testImage,
//       description: "image",
//     },
//   },
//   //   {
//   //     type: "grid",
//   //     height: "25%",
//   //     numberOfColumns: 3,
//   //     numberOfItems: 9,
//   //   },
//   //   {
//   //     type: "accordion",
//   //     height: "25%",
//   //     alwaysOpen: true,
//   //     accordionContent: accordionContent,
//   //   },
//   //Make one for captions, videos, audio and other input later on
// ];

export const HomeContent = ({ mainHeight }) => {
  const generateContainers = (sectionInfo) => {
    return sectionInfo.map((section, index) => {
      return (
        <Container
          type={"superSection"}
          height={section.height}
          width={"100%"}
          style={section.style}
          index={index}
          contentData={section.contentData}
        />
      );
    });
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {generateContainers(homeContent)}
      <div
        style={{
          height: "10px",
          width: "100%",
          backgroundColor: "black",
          display: "flex",
        }}
      >
        {/* <div
          style={{
            height: "100%",
            width: "50%",
            backgroundColor: "grey",
            display: "flex",
          }}
        > */}
          {/* <Accordion
            height={"25%"}
            width={"100%"}
            alwaysOpen={true}
            rowHeight={50}
            content={[
              { title: "Item 1", content: [{ title: "1" }] },
              { title: "Item 2", content: [{ title: "1" }] },
              { title: "Item 3", content: [{ title: "1" }] },
            ]}
          /> */}
          {/* <Numbers targetNumber={1000} startingValue={0} duration={1000} /> */}
          {/* <Scroller /> */}
        {/* </div> */}
      </div>
      <ScrollingGallery height={400} />
    </div>
  );
};

{
  /* <ForegroundSection background={"linear-gradient(125deg, rgba(240,240,250,1), rgba(225,232,245,1))"} color1={'rgb(250,60,200)'} color2={'rgb(230,160,250)'}></ForegroundSection>
      <BackgroundSection background={"linear-gradient(45deg, hotpink, violet)"}/>
      <ForegroundSection background={"linear-gradient(125deg, rgba(225,232,245,1), rgba(210,225,240,1))"} color1={'rgb(250,60,200)'} color2={'rgb(230,160,250)'}/>
      <BackgroundSection background={"linear-gradient(-30deg, deepskyblue, violet, hotpink)"} />
      <ForegroundSection background={"linear-gradient(-45deg, violet, lavender)"} color1={'rgb(250,60,200)'} color2={'rgb(230,160,250)'}/>
      <BackgroundSection background={"linear-gradient(125deg, rgba(240,240,250,1), rgba(225,232,245,1))"} />
      <ForegroundSection background={"linear-gradient(-45deg, violet, lavender)"} color1={'rgb(150,200,220)'} color2={'rgb(200,230,250)'}/>
      <BackgroundSection background={"linear-gradient(-45deg, violet, lavender)"}/>
      <ForegroundSection background={"linear-gradient(-30deg, deepskyblue, violet, hotpink)"} color1={'rgb(150,200,220)'} color2={'rgb(200,230,250)'}/>
      <BackgroundSection background={"linear-gradient(-30deg, deepskyblue, violet, hotpink)"} />
      <ForegroundSection background={"linear-gradient(45deg, hotpink, violet)"} color1={'rgb(250,60,100)'} color2={'rgb(250,60,200)'}/>
      <BackgroundSection background={"linear-gradient(125deg, rgba(240,240,250,1), rgba(225,232,245,1))"} />
      <ForegroundSection background={"linear-gradient(-45deg, violet, lavender)"} color1={'rgb(250,60,100)'} color2={'rgb(250,60,200)'}/> */
}

// const sectionInfo = {type: 'stacked', contentInfo: [
//   { name: "1", height: 400, width: standardWidth, type: 'scroll', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "2", height: 1000, width: standardWidth, type: 'transition', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "3", height: 600, width: standardWidth, type: 'scroll', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "4", height: 300, width: standardWidth, type: 'transition', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "5", height: 800, width: standardWidth, type: 'scroll', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
//   { name: "6", height: 500, width: standardWidth, type: 'transition', content: <div style={{color:'white', fontSize: '100px'}}>H</div>},
// ]}; //Sections will be separate components as well, which will include contents and color info

//Use some metal gradients as well
/* 
"linear-gradient(45deg, hotpink, violet),
linear - gradient(-45deg, violet, lavender),
  linear - gradient(-30deg, deepskyblue, violet, hotpink),
  linear - gradient(-30deg, deepskyblue, violet, violet),
  linear-gradient(30deg, lavender, white, pink)"
*/
