import React from "react";

export const LoadingScreen = () => {
  return (
    <div
      className="loading-container"
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loader" style={{position: 'absolute', top: '35vh'}}>
        <div
          className="arrow-left"
          style={{ position: "absolute", left: "-150px" }}
        >
          <div
            style={{
              backgroundColor: "white",
              height: "100px",
              width: "80px",
              transform: "skew(45deg)",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "white",
              height: "100px",
              width: "80px",
              transform: "skew(-45deg)",
            }}
          ></div>
        </div>
        <div className="arrow-right"
          style={{ position: "absolute", right: "-150px"}}
        >
          <div
            style={{
              backgroundColor: "white",
              height: "100px",
              width: "80px",
              transform: "skew(-45deg)",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "white",
              height: "100px",
              width: "80px",
              transform: "skew(45deg)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
