export const VideoTitle = ({ title }) => {
  return (
    <div
      className="title-container"
      style={{
        height: "66%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <header
        className="video-title"
        style={{
          height: "50%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "100px",
        }}
      >
        {title}
      </header>
    </div>
  );
};
