import React from "react";
import { Container, renderContent } from "./Container";

export const Card = ({ type, width, content, style, index = 0 }) => {
  return (
    <Container
      type={type}
      height={"100%"}
      width={width}
      style={style}
      index={index}
      contentData={content}
    />
  );
};
