import { useEffect, useState } from "react";

export const useScrollPosition = (scrollFactor = 0) => {
  const [position, setPosition] = useState(0);
  const onscroll = () => {
    setPosition(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, []);
  return position * scrollFactor;
};
