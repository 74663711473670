import checkerPattern from "../../assets/images/footer_checker_pattern.png";
import { Logo } from "./Logo";

export const Footer = ({ height }) => {
  return (
    <footer
      style={{
        width: "100%",
        height: `${height}px`,
        backgroundImage: `linear-gradient(90deg, rgba(15,15,15,1), rgba(2,2,2,1) 35%, rgba(9,7,6,1) 50%, rgba(3, 6, 8,1) 50.01%, rgba(2,2,2,1) 65%, rgba(15,15,15,1))`,
        fontSize: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "rgba(243,245,250, 1)",
        boxShadow: " 0px 0px 10px 1px rgba(0,0,0, 0.4)",
        // borderTop: "1px solid rgba(255,255,255, 0.9)",
        position: "relative",
        zIndex: "10",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundSize: "50% 100%",
          backgroundImage: `url(${checkerPattern})`,
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            className="left-side"
            style={{
              borderRight: "1px solid white",
              height: "90%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
              fontSize: "16px",
            }}
          >
            <Logo size={40} />
            <div>© 2024 The Solutioners. All rights reserved.</div>
          </div>
          <div
            className="right-side"
            style={{
              height: "90%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "70%",
                width: "38%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <div>support@thesolutioners.ca</div>
              <div>+1-647-524-3894</div>
              <div>100 Mural St, Richmond Hill, ON L4B 1J3</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
